import React, { Component } from "react";
import { Row, Col, Button, Form, Select, message, Input, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "./Document.css";
import { withRouter } from "react-router-dom";
import { API } from "../../../../API/API";
import UserContext from "../../../UserContext/UserContext";
class DocumentAdd extends Component {
  static contextType = UserContext;
  formRef = React.createRef();
  state = {
    tenderid: this.props.tenderid,
    docid: this.props.docid,
    invitationid: this.props.invitationid,
    isEdit: this.props.isEdit,
    requiretypelist: [],
    requiretypeid: null,
    batchid: null,
    batchlist: [],
    documentcode: "",
    documentname: "",
    filename: "",
    filedata: null,
    filelist: [],
    dkey: 1,
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    API.get(
      "/getTenderDocJoinData?joindocid=" +
        this.state.docid + "&invitationid=" + this.state.invitationid + "&tenderid=" + this.state.tenderid
    ).then((response) => {
      if (response.status === 200) {
        this.setState({
           requiretypelist: response.data.retData[1],
           batchlist: response.data.retData[2],
        });
        if (response.data.retData[0].length > 0) {
          this.setState((prevState) => ({
            requiretypeid: response.data.retData[0][0].requiretypeid,
            documentname: response.data.retData[0][0].documentname,
            filename: response.data.retData[0][0].filename,
            dkey: prevState.dkey + 1,
          }));
        }
      }
    });
  };
  fileOnChange = (info) => {
    this.setState({
      filelist: info.fileList,
      filedata: info.file,
      filename: info.file.name,
    });
  };
  onChangeType = (value) => {
    this.setState({ requiretypeid: value });
  };

  onSave = () => {
    if (
      this.state.requiretypeid &&
      this.state.filedata
    ) {
      let body = {
        invitationid: this.state.invitationid,
        documentname: this.state.filename,
        vendorid: this.context.vendorid,
        requiretypeid: this.state.requiretypeid,
        batchid: this.state.batchid ? this.state.batchid : 0
      };
      API.post("/saveTenderJoinDoc", body).then((response) => {
        if (response.status === 200 && response.data.retType === 0) {
            let docid = response.data.retData;
            const formData = new FormData();
            formData.append(this.state.filename, this.state.filedata.originFileObj);
            API.post(
              "/saveTenderDocFile?sourcetype=TenderJoinDoc&docid=" + docid,
              formData
            ).then((response) => {
              if (response.status === 200 && response.data.retType === 0) {
                message.success("Амжилттай хадгаллаа.");
                this.props.showForm(false, 0);
              }
            });
          }
      });
    }
  };
  onChangeBatch = (value) => {
    this.setState({ batchid: value });
  };
  render() {
    return (
      <div id="document-add" key={this.state.dkey}>
        <Form
          className="formDrawer"
          ref={this.formRef}
          onFinish={this.onSubmit}
          layout="vertical"
          initialValues={{}}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                style={{ fontWeight: 700 }}
                label="Бүрдүүлэх материалын төрөл"
                name="requiretypeid"
                initialValue={this.state.requiretypeid}
                rules={[
                  {
                    required: true,
                    message: "Төрөл сонгоно уу.",
                  },
                ]}
              >
                <Select
                  style={{ fontWeight: 400, width: "100%", height: 30 }}
                  showSearch
                  allowClear
                  optionFilterProp="name"
                  onChange={this.onChangeType}
                  defaultValue={this.state.requiretypeid}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.requiretypelist &&
                    this.state.requiretypelist?.map((t, i) => (
                      <Select.Option key={i} value={t.id}>
                        {t.requirevalue}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {this.state.batchlist.length > 0 ? 
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                style={{ fontWeight: 700 }}
                label="Багц сонгох"
                name="batchid"
                initialValue={this.state.batchid}
              >
                <Select
                  style={{ fontWeight: 400, width: "100%", height: 30 }}
                  showSearch
                  allowClear
                  optionFilterProp="name"
                  onChange={this.onChangeBatch}
                  defaultValue={this.state.batchid}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.batchlist &&
                    this.state.batchlist?.map((t, i) => (
                      <Select.Option key={i} value={t.batchid}>
                        {t.batchname}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row> : null}
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                style={{ fontWeight: 700 }}
                label="Файл оруулах"
                name="filename"
                rules={[
                  {
                    required: true,
                    message: "Файл оруулна уу.",
                  },
                ]}
              >
                <Upload
                  accept="*"
                  openFileDialogOnClick
                  onChange={this.fileOnChange}
                  showUploadList={false}
                  fileList={this.state.filelist}
                >
                  <Button style={{ width: "100%" }}>
                    <Row align="middle" justify="space-between">
                      <Col>{this.state.filename}</Col>
                      <Col>
                        <UploadOutlined />
                      </Col>
                    </Row>
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="buttonForm" label=" ">
            <Button
              type="primary"
              style={{
                width: "100%",
                backgroundColor: "#0358A7",
                borderRadius: 2,
                height: 40,
              }}
              htmlType="submit"
              onClick={this.onSave}
            >
              Баримт, бичиг нэмэх
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default withRouter(DocumentAdd);
