import React, { Component } from "react";
import { Row, Col, Menu, Dropdown, Avatar } from "antd";
import { UserOutlined, MenuOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link, withRouter } from "react-router-dom";
import UserContext from "../../Page/UserContext/UserContext";
import "./HeaderNew.css";
import { NavLink } from "react-router-dom";
import { Button, Drawer } from 'antd';
// import Logo from "../../images/Logo.png";
import Logo from "../../svg/svgicon/LogoSmall";

import NotificationIcon from "../../svg/svgicon/NotificationIcon";

class Header extends Component {
  state = {
      menu: [],
      sMenu: this.props.sMenu,
      visible: false,
      isLoginActive: "username_active",
      isRegisterActive: "username"
  };

  onMenuChange = (e) => {
    if (e.key) {
        this.setState({
          sMenu: parseInt(e.key),
        });
    }
  };
  changeStyle = () => {
    if (this.state.isRegisterActive === "username") {
        this.setState({isRegisterActive: "username_active"});
        this.setState({isLoginActive: "username"});
    } else {
      this.setState({isRegisterActive: "username"});
      this.setState({isLoginActive: "username_active"});
    } 
  }

  changeStyleLogin = () => {
    if (this.state.isLoginActive === "username") 
      {
        this.setState({isLoginActive: "username_active"})
        this.setState({isRegisterActive: "username"});
      } else 
        this.setState({isLoginActive: "username"});  
  }

  showDrawer = () => {
    console.log("");
    this.setState({visible: true});  
  };

  onClose = () => {
    this.setState({visible: false});
  };

  onLogin = () => {
    this.props.history.push("/login");
  };

  componentDidMount() {
    this.getData();
  }
  getData = () => {
    this.setState({
      menu: [
        // {
        //   id: 0,
        //   name: "ТӨЛӨВЛӨГӨӨ",
        //   page: "/planlist",
        // },
        {
          id: 1,
          name: "ТЕНДЕРИЙН УРИЛГА",
          page: "/invitationoftenderlist",
        },
        {
          id: 2,
          name: "ХАМТРАН АЖИЛЛАХ САНАЛ",
          page: "/joinworkofvendorlist",
        }
      ],
    });
  };

  render() {
    let menu = null;
    if (this.state.menu.length > 0) {
      menu = (
        <Menu className="menu" onClick={this.onMenuChange} mode="horizontal">
          {this.state.menu.map((r) => {
            return (
              <Menu.Item key={r.id}>
                <Link className="menuItem" onClick={this.onClose} to={r.page}>
                  {r.name}
                </Link>
              </Menu.Item>
              
            );
          })}
        </Menu>
      );
    }
    let userInfo = (
      <Menu>
        <Menu.Item
          key={0}
          icon={<LogoutOutlined />}
          style={{ width: "100px", textAlign: "center" }}
          onClick={this.props.logout}>
          <a>Гарах</a>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="menu_background"> 
      <div className='header'>
        <div className="logo">
            <Link to="/"><Logo /></Link>
        </div>  
        <div className="mobileVisible">
          <Button type="primary" onClick={this.showDrawer}>
               <MenuOutlined />
          </Button>
          <Drawer placement="right" onClose={this.onClose} visible={this.state.visible}>
             <Menu className="menu" onClick={this.onMenuChange} mode="vertical">
                {this.state.menu.map((r) => {
                  return (
                    <Menu.Item key={r.id}>
                      <Link className="menuItem" to={r.page} onClick={this.onClose}>{r.name}</Link>
                    </Menu.Item>
                  );
                })}
                {this.props.userid ? (
                  <Menu.Item key="100">
                      <NotificationIcon />
                  </Menu.Item>
                ) : (
                  <Menu.Item key="200">
                    <Link to={"/register"} onClick={this.onClose} className="menuItem">БҮРТГҮҮЛЭХ</Link>
                  </Menu.Item>
                )}
                {this.props.userid ? (
                   <div className="loggedUsername">{this.props.userid}</div>
                ) : (
                  <Menu.Item key="300">
                    <Link to={"/login"} onClick={this.onClose} className="menuItem">НЭВТРЭХ</Link>
                  </Menu.Item>
                )}

                <Row>
                    {this.props.userid ? (
                      <Col>
                        <Dropdown
                          placement="bottomRight"
                          overlay={userInfo}
                          trigger={["click"]}>
                          
                            <Avatar
                              style={{ cursor: "pointer" }}
                              size={32}
                              icon={<UserOutlined />}
                            ></Avatar>
                        </Dropdown>
                      </Col>
                    ) : null}
                </Row>
             </Menu>
          </Drawer>
        </div>
        <div className="mobileHidden">
            <Row gutter={[24, 24]} justify="start" align="middle">
              <Col xs = {16} md = {16} justify="start">{menu}</Col>
              <Col lg={2}>&nbsp;</Col>
                {this.props.userid ? (
                  <Col span={4} justify="end" className="loggedUsername" style={{borderWidth:1, borderStyle:"solid"}}>
                      <NotificationIcon />
                  </Col>
                ) : (
                  <Col span={3} justify="end" style={{textAlign: 'right'}}>
                      <Link className={"primary " + this.state.isRegisterActive + ""} onClick={this.changeStyle} to={"/register"}>
                        Бүртгүүлэх
                      </Link>
                  </Col>  
                )}
                {this.props.userid ? (
                   <div className="loggedUsername">{this.props.userid}</div>
                ) : (
                  <Col span={3}>
                      <Link className={"primary " + this.state.isLoginActive + ""} onClick={this.changeStyleLogin} to={"/login"}>
                        Нэвтрэх
                      </Link>
                  </Col>
                )}
              {this.props.userid ? (
                 <Col span={3}>
                    <Dropdown
                      placement="bottomRight"
                      overlay={userInfo}
                      trigger={["click"]}>
                        <Avatar
                          style={{ cursor: "pointer" }}
                          size={32}
                          icon={<UserOutlined />}
                        ></Avatar>
                    </Dropdown>
                  </Col>
              ) : null}
            </Row>   
        </div>
      </div>
    </div>
    );
  }
}
Header.contextType = UserContext;
export default withRouter(Header);
