import React from "react";
import { Button } from "antd";

const ButtonStage = (props) => {
  return (
    <Button onClick={props.onClick} type="text">
      <div className={props.disabled ? "stageDisabled" : "stage"}>
        <div className={props.disabled ? "stageValueDisabled" : "stageValue"}>
          {props.value}
        </div>
      </div>
    </Button>
  );
};

export default ButtonStage;
