import React from 'react'
// import LoginLogo from "../../svg/svgicon/LoginLogo";
// import Logo from "../../images/Logo.png";
// 

const LeftSide = () => {
  return (
    <div className="loginLeftSide">
        <div className="loginLogo">
          &nbsp;
        </div>
        <div className="loginHeaderLabel">
            Монголын Алт (МАК) ХХК
        </div>
        <div className="loginLeftLabel">
            Энэхүү систем нь Монголын Алт (МАК) ХХК -н цахим тендер
            шалгаруулалтын систем
        </div>
    </div>
  )
}

export default LeftSide