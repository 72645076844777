import React, { Component } from "react";
import { Row, Col, Button, Table } from "antd";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { API } from "../../API/API";
import UserContext from "../UserContext/UserContext";
import "./Home.css";
class Home extends Component {
  static contextType = UserContext;
  state = {
    possibleTender: 0,
    activeTender: 0,
    involvedTender: 0,
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    API.get(
      "/getInvitationCountByVendor?vendorid=" + this.context.vendorid
    ).then((response) => {
      if (response.status === 200 && response.data.retType === 0) {
        this.setState({
          possibleTender: response.data.retData[0].possibleTender,
          activeTender: response.data.retData[0].activeTender,
          involvedTender: response.data.retData[0].involvedTender,
        });
      }
    });
  };
  onCallScreen = (index) => {
    if (index === 0){
      this.props.history.push("/tenderpossiblelist");
    }
    else if (index === 1){
      this.props.history.push("/tenderactivelist");
    }
    else if (index === 2){
      this.props.history.push("/tenderinvolvedlist");
    }
  }
  render() {
    return (
      <div className="mainContainer">
        <Row gutter={(16, 16)}>
          <Col onClick={() => this.onCallScreen(0)} span={8}>
            <div className="Card" style={{ backgroundColor: "#578ebe" }}>
              <div className="Card-Header-Label">Оролцох боломжтой тендер</div>
              <Row justify="end">
                <div className="Card-Content-Label">
                  {this.state.possibleTender}
                </div>
              </Row>
            </div>
          </Col>
          <Col onClick={() => this.onCallScreen(1)} span={8}>
            <div className="Card" style={{ backgroundColor: "#E87E04" }}>
              <div className="Card-Header-Label">Оролцож буй тендер</div>
              <Row justify="end">
                <div className="Card-Content-Label">
                  {this.state.activeTender}
                </div>
              </Row>
            </div>
          </Col>
          <Col onClick={() => this.onCallScreen(2)} span={8}>
            <div className="Card" style={{ backgroundColor: "#F3C200" }}>
              <div className="Card-Header-Label">Оролцсон тендер</div>
              <Row justify="end">
                <div className="Card-Content-Label">
                  {this.state.involvedTender}
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(Home);
