import React, { Component } from "react";
import { Row, Col, List, Card } from "antd";
import { withRouter } from "react-router-dom";
import "./PlanList.css";
import FilterHeader from "../../Components/MyComponent/FilterHeader";
import { API } from "../../API/API";
class PlanList extends Component {
  state = {
    data: [],
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    API.get("/getTenderPlanList").then((response) => {
      if (response.status === 200) {
        this.setState({
          data: response.data.retData,
        });
      }
    });
  };
  onSearch = (filterDate, tenderName, invitationCode, tenderTypeID) => {
    let body = {
      filterDate: filterDate,
      tenderName: tenderName,
      tenderTypeID: tenderTypeID,
    };
    API.post("/getTenderPlanListByFilter", body).then((response) => {
      if (response.status === 200) {
        this.setState({
          data: response.data.retData,
        });
      }
    });
  };
  render() {
    return (
      <div id="plan">
        <FilterHeader onSearch={this.onSearch} />
        <div style={{ paddingRight: 100, paddingLeft: 100, marginTop: 15 }}>
          <div className="HeaderLabel">
            Удахгүй зарлагдах тендерийн жагсаалт
          </div>
          <List
            grid={{ gutter: 16, column: 1 }}
            dataSource={this.state.data}
            renderItem={(item) => (
              <List.Item span={24}>
                <Card.Grid className="card">
                  <div>
                    <Row
                      align="middle"
                      style={{ marginTop: -5, marginBottom: -5 }}
                      gutter={(16, 16)}
                    >
                      <Col span={20}>
                        <Row gutter={[16, 16]}>
                          <Col style={{ marginTop: -10 }} span={24}>
                            <div className="tenderName">{item.tendername}</div>
                          </Col>
                          <Col style={{ marginTop: -10 }} span={24}>
                            <div className="cardLabel">
                              Тендерийн дугаар: {item.tendercode}
                            </div>
                          </Col>
                          <Col style={{ marginTop: -10 }} span={24}>
                            <Row justify="start">
                              <Col>
                                <div className="cardLabel">
                                  Тендерийн төрөл:
                                </div>
                              </Col>
                              <Col>
                                <div
                                  style={{
                                    marginLeft: 5,
                                    color: "#605E5C",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {item.tendertypename}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="cardDivider" span={4}>
                        <Row align="middle" gutter={[16, 16]}>
                          <Col span={24}>
                            <div
                              className="cardLabel"
                              style={{ color: "#605E5C", fontWeight: 400 }}
                            >
                              Зарлах огноо:{" "}
                              <div className="cardLabel">
                                {item.publishdate}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Card.Grid>
              </List.Item>
            )}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(PlanList);
