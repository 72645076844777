import React, { Component } from "react";
import { Row, Col, Space, Typography } from "antd";
import { withRouter } from "react-router-dom";
import "./JoinWorkList.css";
import RightSide from "../../Components/MyComponent/RightSide";
import { API } from "../../API/API"
const { Text } = Typography;
const Str = require('@supercharge/strings');
class JoinWorkOfVendorDtl extends Component {
  state = {
    joinworkid: this.props.match.params.joinworkid,
    joinworkcode: this.props.match.params.joinworkcode,
    joinworkname: this.props.match.params.joinworkname,
    departmentname: this.props.match.params.departmentname,
    tendertypename: this.props.match.params.tendertypename,
    status: "Хүлээн авч байгаа",
    startdate: "",
    enddate: "",
    note: "",
    text: [],
    popConfirmVisible: false,
    dkey: 1,
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    API.get("/getJoinWorkById?joinworkid=" + this.state.joinworkid).then(
      (response) => {
        if (response.status === 200 && response.data.retType === 0) {
          if (response.data.retData.length > 0) {
            this.setState((prevState) => ({
              joinworkcode: response.data.retData[0].joinworkcode,
              joinworkname: response.data.retData[0].joinworkname,
              departmentname: response.data.retData[0].departmentname,
              tendertypename: response.data.retData[0].tendertypename,
              startdate: response.data.retData[0].startdate,
              enddate: response.data.retData[0].enddate,
              note: response.data.retData[0].note,
              text: Str(response.data.retData[0].note).lines(),
              dkey: prevState.dkey + 1,
            }));
          }
        }
      }
    );
  };
  render() {
    return (
      <div id="joinwork-list" key={this.state.dkey}>
        <div style={{ paddingRight: 120, paddingLeft: 100 }}>
          <Row gutter={(16, 16)}>
            <Col span={17}>
              <div style={{ backgroundColor: "#fff" }}>
                <div style={{ padding: 20 }}>
                  <div className="HeaderLabel" style={{ marginBottom: 15 }}>
                    {this.props.match.params.joinworkname}
                  </div>
                  <div style={{ padding: 15 }}>
                    <Row gutter={(16, 16)}>
                      <Col className="headerTable-leftSide" span={12}>
                        <div className="headerTable-leftSide-Text">Захиалагч:</div>
                      </Col>
                      <Col className="headerTable-rightSide" span={12}>
                        <div className="headerTable-rightSide-Text">
                          Монголын алт ХХК - Хангамж техник үйлчилгэээний газар
                        </div>
                      </Col>
                      <Col className="headerTable-leftSide" span={12}>
                        <div className="headerTable-leftSide-Text">
                          Хамтран ажиллах саналын төрөл:
                        </div>
                      </Col>
                      <Col className="headerTable-rightSide" span={12}>
                        <div className="headerTable-rightSide-Text">
                          {this.state.tendertypename}
                        </div>
                      </Col>
                      <Col className="headerTable-leftSide" span={12}>
                        <div className="headerTable-leftSide-Text">
                          Хамтран ажиллах саналын дугаар:
                        </div>
                      </Col>
                      <Col className="headerTable-rightSide" span={12}>
                        <div className="headerTable-rightSide-Text">
                          {this.state.joinworkcode}
                        </div>
                      </Col>
                      <Col className="headerTable-leftSide" span={12}>
                        <div className="headerTable-leftSide-Text">
                          Хамтран ажиллах саналын төлөв:
                        </div>
                      </Col>
                      <Col className="headerTable-rightSide" span={12}>
                        <div className="headerTable-rightSide-Text">
                          {this.state.status}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="content-Text" style={{ marginTop: 5 }}>
                    <Space direction="vertical">
                      {this.state.text.map(value => 
                        <Text>{value}</Text>
                      )
                      }
                    </Space>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={7}>
              <RightSide
                enddate={this.state.enddate}
                enddateLabel="Дуусах хугацаа"
                balanceday={3}
                buttonText="Санал илгээх"
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(JoinWorkOfVendorDtl);
