import React from "react";

const TederPossibleIcon = (props) => {
  return (
    <svg
      style={{ marginRight: 7 }}
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5415 0.333252C1.50597 0.333252 0.666504 1.17272 0.666504 2.20825V4.29158C0.666504 5.32712 1.50597 6.16658 2.5415 6.16658H4.62484C5.66037 6.16658 6.49984 5.32712 6.49984 4.29158V2.20825C6.49984 1.17272 5.66037 0.333252 4.62484 0.333252H2.5415ZM1.9165 2.20825C1.9165 1.86308 2.19633 1.58325 2.5415 1.58325H4.62484C4.97001 1.58325 5.24984 1.86308 5.24984 2.20825V4.29158C5.24984 4.63676 4.97001 4.91658 4.62484 4.91658H2.5415C2.19633 4.91658 1.9165 4.63676 1.9165 4.29158V2.20825ZM8.37484 1.16659C8.02967 1.16659 7.74984 1.44641 7.74984 1.79159C7.74984 2.13676 8.02967 2.41658 8.37484 2.41658H16.7082C17.0533 2.41658 17.3332 2.13676 17.3332 1.79159C17.3332 1.44641 17.0533 1.16659 16.7082 1.16659H8.37484ZM8.37484 3.66658C8.02967 3.66658 7.74984 3.94641 7.74984 4.29158C7.74984 4.63676 8.02967 4.91658 8.37484 4.91658H14.2082C14.5533 4.91658 14.8332 4.63676 14.8332 4.29158C14.8332 3.94641 14.5533 3.66658 14.2082 3.66658H8.37484ZM2.5415 7.83325C1.50597 7.83325 0.666504 8.67275 0.666504 9.70825V11.7916C0.666504 12.8271 1.50597 13.6666 2.5415 13.6666H4.62484C5.66037 13.6666 6.49984 12.8271 6.49984 11.7916V9.70825C6.49984 8.67275 5.66037 7.83325 4.62484 7.83325H2.5415ZM1.9165 9.70825C1.9165 9.36308 2.19633 9.08325 2.5415 9.08325H4.62484C4.97001 9.08325 5.24984 9.36308 5.24984 9.70825V11.7916C5.24984 12.1368 4.97001 12.4166 4.62484 12.4166H2.5415C2.19633 12.4166 1.9165 12.1368 1.9165 11.7916V9.70825ZM8.37484 8.66658C8.02967 8.66658 7.74984 8.94642 7.74984 9.29158C7.74984 9.63675 8.02967 9.91658 8.37484 9.91658H16.7082C17.0533 9.91658 17.3332 9.63675 17.3332 9.29158C17.3332 8.94642 17.0533 8.66658 16.7082 8.66658H8.37484ZM8.37484 11.1666C8.02967 11.1666 7.74984 11.4464 7.74984 11.7916C7.74984 12.1368 8.02967 12.4166 8.37484 12.4166H14.2082C14.5533 12.4166 14.8332 12.1368 14.8332 11.7916C14.8332 11.4464 14.5533 11.1666 14.2082 11.1666H8.37484Z"
        fill="#605E5C"
      />
    </svg>
  );
};

export default TederPossibleIcon;
