import React, { Component } from "react";
import { Row, Col, List, Card } from "antd";
import { MenuOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "./InvitationList.css";
import FilterHeader from "../../Components/MyComponent/FilterHeader";
import { API } from "../../API/API";
class InvitationOfTenderList extends Component {
  state = {
    data: [],
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    API.get("/getInvitationListForVendor").then((response) => {
      if (response.status === 200) {
        this.setState({
          data: response.data.retData,
        });
      }
    });
  };
  onSearch = (filterDate, tenderName, invitationCode, tenderTypeID) => {
    let body = {
      filterDate: filterDate,
      tenderName: tenderName,
      invitationCode: invitationCode,
      tenderTypeID: tenderTypeID,
    };
    API.post("/getInvitationListByFilter", body).then((response) => {
      if (response.status === 200) {
        this.setState({
          data: response.data.retData,
        });
      }
    });
  };
  render() {
    return (
      <div id="invitationOfTender">
        <FilterHeader onSearch={this.onSearch} />
        <div style={{ paddingRight: 100, paddingLeft: 100, marginTop: 15 }}>
          <div className="HeaderLabel">Сүүлд зарлагдсан тендерийн урилга</div>
          <List
            grid={{ gutter: 16, column: 1 }}
            dataSource={this.state.data}
            renderItem={(item) => (
              <List.Item span={24}>
                <Card.Grid className="card">
                  <Row
                    align="middle"
                    style={{ marginTop: -15, marginBottom: -15 }}
                    gutter={(16, 16)}
                  >
                    <Col span={20}>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Link
                            className="invitationName"
                            to={item.status === 1 ?
                              "/invitationoftender/" +
                              item.invitationid +
                              "/" +
                              item.tendername
                              : "#"
                            }
                          >
                            {item.tendername}
                          </Link>
                        </Col>
                        <Col style={{ marginTop: -10 }} span={24}>
                          <div className="f b">
                            Урилгын дугаар:{" "}
                            <Link
                              style={{ color: "#0358a7" }}
                              to={item.status === 1 ?
                                "/invitationoftender/" +
                                item.invitationid +
                                "/" +
                                item.tendername
                                : "#"
                              }
                            >
                              {item.invitationcode}
                            </Link>
                          </div>
                        </Col>
                        <Col style={{ marginTop: -10 }} span={24}>
                          <Row justify="start">
                            <Col>
                              <div className="cardLabel">Тендерийн төрөл:</div>
                            </Col>
                            <Col>
                              <div
                                style={{
                                  marginLeft: 5,
                                  color: "#605E5C",
                                  fontWeight: "normal",
                                }}
                              >
                                {item.tendertypename}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="cardDivider" span={4}>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div
                            className="cardLabel"
                            style={{ color: "#605E5C", fontWeight: 400 }}
                          >
                            Зарласан огноо:{" "}
                            <div className="cardLabel">{item.publishdate}</div>
                          </div>
                        </Col>
                        {item.status === 8 ? 
                        <Col style={{ marginTop: -10 }} span={24}>
                          <div
                            style={{
                              marginLeft: 5,
                              color: "red",
                              fontWeight: "normal",
                            }}
                          >
                            Цуцлагдсан
                          </div>
                        </Col>
                        :
                        <div>
                          <Col style={{ marginTop: -10 }} span={24}>
                            <div
                              className="cardLabel"
                              style={{ color: "#605E5C", fontWeight: 400 }}
                            >
                              Хүлээн авах эцсийн хугацаа:
                            </div>
                          </Col>,
                          <Col style={{ marginTop: -20 }} span={24}>
                            <Row justify="start">
                              <Col>
                                <div
                                  className="cardLabel"
                                  style={{
                                    color:
                                      item.balanceday < 4 ? "#FF0000" : "#212121",
                                  }}
                                >
                                  {item.acceptdate}
                                </div>
                              </Col>
                              <Col>
                                <div
                                  style={{
                                    marginLeft: 5,
                                    color: "#605E5C",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {item.balanceday < 0
                                    ? "Хугацаа дууссан"
                                    : "/" + item.balanceday + " өдөр үлдсэн/"}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </div>
                        }
                      </Row>
                    </Col>
                  </Row>
                </Card.Grid>
              </List.Item>
            )}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(InvitationOfTenderList);
