import React, { Component } from "react";
import { Menu, Button } from "antd";
import { Link, withRouter } from "react-router-dom";
import HomeIcon from "../../svg/svgicon/menuIcons/homeIcon";
import CompanyInfoIcon from "../../svg/svgicon/menuIcons/CompanyInfoIcon";
import TenderSubMenuIcon from "../../svg/svgicon/menuIcons/TenderSubMenuIcon";
import TenderPossibleIcon from "../../svg/svgicon/menuIcons/TenderPossibleIcon";
import TenderJoinedIcon from "../../svg/svgicon/menuIcons/TenderJoinerIcon";
import TenderEndedIcon from "../../svg/svgicon/menuIcons/TenderEndedIcon";
import JoinWorkMenuIcon from "../../svg/svgicon/menuIcons/JoinWorkMenuIcon";
const { SubMenu } = Menu;
class MenuComponent extends Component {
  state = {
    visible: false,
    collapsed: true,
  };
  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });  
  };

  onMenuChange = (e) => {
    if (e.key) {
      this.setState({
        sMenu: parseInt(e.key),
      });
    }
  };
 
  render() {
    let menu =
      this.props.menuType === "admin" ? null : (
        <Menu
          defaultSelectedKeys={["1"]}
          mode="inline"
          theme="light"
          onClick={this.onMenuChange}
          collapsed={this.toggleCollapsed}
          // visible={this.state.visible}
        >
          <Menu.Item key={0} icon={<HomeIcon />}>
            <Link className="MenuLabel" to="/home">ХЯНАЛТЫН САМБАР</Link>
          </Menu.Item>
          <Menu.Item key={1} icon={<CompanyInfoIcon />}>
            <Link className="MenuLabel" to="/companyinfo" onClick={() => this.toggleCollapsed(this.state.collapsed)} >БАЙГУУЛЛАГЫН МЭДЭЭЛЭЛ </Link>
          </Menu.Item>
          <SubMenu
            collapsed={true}
            key="sub1"
            icon={<TenderSubMenuIcon />}
            className="MenuLabel"
            title="ТЕНДЕР ШАЛГАРУУЛАЛТ"
          >
            <Menu.Item key={2} icon={<TenderPossibleIcon />}>
              <Link to="/tenderpossiblelist" className="MenuLabel">Боломжит тендерүүд</Link>
            </Menu.Item>
            <Menu.Item key={3} icon={<TenderJoinedIcon />}>
              <Link to="/tenderactivelist" className="MenuLabel">Оролцож буй тендерүүд</Link>
            </Menu.Item>
            <Menu.Item key={4} icon={<TenderEndedIcon />}>
              <Link to="/tenderinvolvedlist" className="MenuLabel">Оролцсон тендерүүд</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            collapsed={true}
            key="sub2"
            icon={<JoinWorkMenuIcon />}
            className="MenuLabel"
            title="ХАМТРАН АЖИЛЛАХ САНАЛ"
          >
            <Menu.Item key={5} icon={<TenderPossibleIcon />}>
              <Link to="/joinworklist" className="MenuLabel">Боломжит хамтран ажиллах санал</Link>
            </Menu.Item>
            <Menu.Item key={6} icon={<TenderJoinedIcon />}>
              <Link to="/joinworkinvolved" className="MenuLabel">Оролцсон хамтран ажиллах санал</Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      );

    return <div id="menuComponent">{menu}</div>;
  }
}

export default withRouter(MenuComponent);
