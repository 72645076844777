import React, { Component } from "react";
import { Row, Col, Input, Button, Table, Popover, message, Upload } from "antd";
import { withRouter } from "react-router-dom";
import FileDownload from "js-file-download";
import PopConfirm from "../../Components/MyComponent/PopConfirm";
import ButtonLinkIcon from "../../Components/MyComponent/ButtonLinkIcon";
import PlusIcon from "../../svg/svgicon/PlusIcon";
import { API } from "../../API/API";
import UserContext from "../UserContext/UserContext";
class JoinWorkRequest extends Component {
  static contextType = UserContext;
  formRef = React.createRef();
  state = {
    joinworkid: this.props.match.params.joinworkid,
    joinworkcode: this.props.match.params.joinworkcode,
    joinworkname: this.props.match.params.joinworkname,
    tendertypename: this.props.match.params.tendertypename,
    enddate: this.props.match.params.enddate,
    popConfirmVisible: false,
    data: [],
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    API.get(
      "/getJoinWorkDocList?vendorid=" +
        this.context.vendorid +
        "&joinworkid=" +
        this.state.joinworkid
    ).then((response) => {
      if (response.status === 200) {
        this.setState({
          data: response.data.retData,
        });
      }
    });
  };
  onDeleteFile = (sourceid, sourcetype) => {
    API.get("/deleteFile?sourceid=" + sourceid + "&sourcetype=" + sourcetype, {
      responseType: "blob",
    }).then((response) => {
      if (response.status === 200) {
        this.getData();
      }
    });
  };
  onDownLoadFile = (sourceid, sourcetype, filename) => {
    API.get("/download?sourceid=" + sourceid + "&sourcetype=" + sourcetype, {
      responseType: "blob",
    }).then((response) => {
      if (response.status === 200) {
        FileDownload(response.data, filename);
      }
    });
  };
  uploadFile = (info) => {
    if (info.file.status != "uploading") {
      let body = {
        joinworkid: this.state.joinworkid,
        documentname: info.file.name,
        vendorid: this.context.vendorid,
      };
      API.post("/saveJoinwWorkDoc", body).then((response) => {
        if (response.status === 200 && response.data.retType === 0) {
          let docid = response.data.retData;
          const formData = new FormData();
          formData.append(info.file.name, info.file.originFileObj);
          API.post(
            "/saveTenderDocFile?sourcetype=JoinWorkDoc&docid=" + docid,
            formData
          ).then((response) => {
            if (response.status === 200 && response.data.retType === 0) {
              message.success("Амжилттай хадгаллаа.");
              this.getData();
            }
          });
        }
      });
    }
  };
  popConfirm = () => {
    return (
      <PopConfirm
        question="Та хамтран ажиллах санал илгээхдээ итгэлтэй байнуу?"
        onClickYes={this.onCallRequest}
        onClickNo={() => this.onChangePopConfirmVisible(false)}
      />
    );
  };
  onChangePopConfirmVisible = (visible) => {
    this.setState({ popConfirmVisible: visible });
  };
  onCallRequest = () => {
    this.setState({ popConfirmVisible: false });
    let body = {
      id: 0,
      vendorid: localStorage.getItem("vendorid"),
      joinworkid: this.state.joinworkid,
      status: 12,
      createdby: localStorage.getItem("userid")
    };
    API.post("/sendJoinWorkOfVendor", body).then((response) => {
      if (response.status === 200) {
        message.success("Амжилттай илгээгдлээ.");
        this.props.history.push("/joinworkinvolved");
      }
    });
  };
  render() {
    const columns = [
      {
        title: "Нэр",
        dataIndex: "filename",
        key: "filename",
        width: "20%",
        align: "left",
        render: (text, record) => {
          return (
            <Button
              type="link"
              style={{ fontWeight: "500", color: "#0358A7" }}
              onClick={() =>
                this.onDownLoadFile(
                  record.sourceid,
                  record.sourcetype,
                  record.filename
                )
              }
            >
              {record.filename}
            </Button>
          );
        },
      },
      {
        title: "Үйлдэл",
        dataIndex: "action",
        key: "action",
        width: "20%",
        align: "left",
        render: (text, record) => {
          return (
            <Row justify="start">
              <Button
                type="link"
                style={{ fontWeight: "500", color: "#0358A7" }}
                onClick={() =>
                  this.onDownLoadFile(
                    record.sourceid,
                    record.sourcetype,
                    record.filename
                  )
                }
              >
                Татах
              </Button>
              <div
                style={{
                  marginTop: 5,
                  fontWeight: "500",
                  color: "#0358A7",
                }}
              >
                |
              </div>
              <Button
                type="link"
                style={{ fontWeight: "500", color: "#0358A7" }}
                onClick={() =>
                  this.onDeleteFile(record.sourceid, record.sourcetype)
                }
              >
                Устгах
              </Button>
            </Row>
          );
        },
      },
    ];
    return (
      <div className="mainContainer">
        <div className="HeaderLabel">
          Хамтран ажиллах санал илгээх | {this.state.joinworkname}
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={16} md={12} lg={8}>
            <div className="Label" style={{ marginBottom: 5 }}>
              Хамтран ажиллах саналын код
            </div>
            <Input size="middle" value={this.state.joinworkcode} />
          </Col>
          <Col xs={16} md={12} lg={8}>
            <div className="Label" style={{ marginBottom: 5 }}>
              Төрөл
            </div>
            <Input size="middle" value={this.state.tendertypename} />
          </Col>
          <Col xs={16} md={12} lg={8}>
            <div className="Label" style={{ marginBottom: 5 }}>
              Хүлээн авах эцсийн хугацаа
            </div>
            <Input size="middle" value={this.state.enddate} />
          </Col>
        </Row>

        <Row style={{ height: 56, marginTop:10 }} align="left">
          <Col  xs={8} md={8} lg={3}>
              <div className="HeaderLabel">Баримт, бичиг</div>
          </Col>
          <Col style={{ height: 56, marginTop:12 }}><div className="divider"></div></Col>
          <Col span={8}>
              <Upload
                accept="*"
                openFileDialogOnClick
                onChange={this.uploadFile}
                showUploadList={false}
                multiple={false}
              >
                <ButtonLinkIcon
                  icon={<PlusIcon />}
                  name="Баримт бичиг нэмэх"
                />
              </Upload>
          </Col>
        </Row>

        <div id="criteria">
          <Table
            dataSource={this.state.data}
            columns={columns}
            pagination={false}
            rowKey="documentid"
            scroll={{ y: 340 }}
          />
        </div>
        <Row justify="start" align="middle">
          <Col>
            <Popover
              placement="bottomRight"
              title="Санамж"
              content={this.popConfirm}
              trigger="click"
              visible={this.state.popConfirmVisible}
            >
              <Button
                type="primary"
                style={{
                  backgroundColor: "#0358A7",
                  borderRadius: 4,
                  marginTop: 20,
                }}
                htmlType="submit"
                onClick={() => this.onChangePopConfirmVisible(true)}
              >
                Илгээх
              </Button>
            </Popover>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(JoinWorkRequest);
