import React from "react";

const CompanyInfoIcon = (props) => {
  return (
    <svg
      style={{ marginRight: 7 }}
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4582 0.333252C16.4937 0.333252 17.3332 1.17272 17.3332 2.20825V11.7954C17.3332 12.8309 16.4937 13.6704 15.4582 13.6704H2.5415C1.50597 13.6704 0.666504 12.8309 0.666504 11.7954V2.20825C0.666504 1.17272 1.50597 0.333252 2.5415 0.333252H15.4582ZM15.4582 1.58325H2.5415C2.19633 1.58325 1.9165 1.86308 1.9165 2.20825V11.7954C1.9165 12.1406 2.19633 12.4204 2.5415 12.4204H15.4582C15.8033 12.4204 16.0832 12.1406 16.0832 11.7954V2.20825C16.0832 1.86308 15.8033 1.58325 15.4582 1.58325ZM7.12484 7.41659C7.47 7.41659 7.74984 7.69642 7.74984 8.04159V8.45217L7.74325 8.54267C7.60759 9.47009 6.83123 9.91734 5.66646 9.91734C4.50161 9.91734 3.72524 9.46967 3.58973 8.54167L3.58317 8.45142V8.04159C3.58317 7.69642 3.863 7.41659 4.20817 7.41659H7.12484ZM10.0434 7.83H13.7915C14.1367 7.83 14.4165 8.10984 14.4165 8.455C14.4165 8.77142 14.1813 9.03292 13.8763 9.07434L13.7915 9.08H10.0434C9.69825 9.08 9.41842 8.80025 9.41842 8.455C9.41842 8.13859 9.6535 7.87709 9.95859 7.83575L10.0434 7.83H13.7915H10.0434ZM5.6665 4.08507C6.35685 4.08507 6.91648 4.6447 6.91648 5.33509C6.91648 6.02542 6.35685 6.585 5.6665 6.585C4.97616 6.585 4.41653 6.02542 4.41653 5.33509C4.41653 4.6447 4.97616 4.08507 5.6665 4.08507ZM10.0434 4.91659H13.7915C14.1367 4.91659 14.4165 5.19641 14.4165 5.54159C14.4165 5.858 14.1813 6.1195 13.8763 6.16092L13.7915 6.16659H10.0434C9.69825 6.16659 9.41842 5.88675 9.41842 5.54159C9.41842 5.22517 9.6535 4.96368 9.95859 4.92229L10.0434 4.91659H13.7915H10.0434Z"
        fill="#605E5C"
      />
    </svg>
  );
};

export default CompanyInfoIcon;
