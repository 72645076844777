import React from "react";

const StageSuccess = (props) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.77544 5.808C1.53136 5.56392 1.13563 5.56392 0.891552 5.808C0.647477 6.05208 0.647477 6.44775 0.891552 6.69183L4.64155 10.4418C4.88563 10.6859 5.28136 10.6859 5.52543 10.4418L14.6921 1.27519C14.9362 1.03112 14.9362 0.635383 14.6921 0.391308C14.448 0.147233 14.0523 0.147233 13.8082 0.391308L5.08349 9.116L1.77544 5.808Z"
        fill="white"
      />
    </svg>
  );
};

export default StageSuccess;
