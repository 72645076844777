import React from "react";
let baseURI = window.location.protocol
  .concat("//")
  .concat(window.location.hostname);

  const baseConfig = {
    // loginurl: "http://localhost:5000",
    // baseurl: "http://localhost:5000",
    // makurl: "http://localhost:8069/",
    makurl: "https://erp.mak.mn", 
    loginurl: baseURI,
    baseurl: baseURI,
};

export { baseConfig };

