import React, { Component } from "react";
import { Row, Col, Button, Popover, message, Tabs, Input } from "antd";
import { withRouter } from "react-router-dom";
import StageSuccess from "../../../svg/svgicon/StageIcon/StageSuccess";
import ButtonStage from "../../../Components/MyComponent/ButtonStage";
import PopConfirm from "../../../Components/MyComponent/PopConfirm";
import DocumentList from "./Documents/DocumentList";
import QouteList from "./Qoute/QouteList";
import { API } from "../../../API/API";
import UserContext from "../../UserContext/UserContext";
import SendIcon from "../../../svg/svgicon/SendIcon";
const { TabPane } = Tabs;
const { TextArea } = Input;
const dateFormat = "YYYY.MM.DD HH:mm";
var moment = require("moment");
class TenderJoin extends Component {
  static contextType = UserContext;
  formRef = React.createRef();
  state = {
    isEdit: this.props.match.params.isedit,
    invitationid: this.props.match.params.invid,
    invitationcode: this.props.match.params.invcode,
    tenderid: this.props.match.params.tenderid,
    tendercode: this.props.match.params.tendercode,
    tendername: this.props.match.params.tendername,
    tendertypename: this.props.match.params.tendertypename,
    acceptdate: this.props.match.params.acceptdate,
    stage: 1,
    commentlist: [],
    comment: "",
    popConfirmVisible: false,
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    API.get(
      "/getCommentsByVendorId?invitationid=" +
        this.state.invitationid +
        "&vendorid=" +
        this.context.vendorid
    ).then((response) => {
      if (response.status === 200 && response.data.retType === 0) {
        this.setState({ commentlist: response.data.retData });
      }
    });
  };
  onChangeComment = (value) => {
    this.setState({ comment: value });
  };
  onSendComment = () => {
    let body = {
      commentid: 0,
      commenttitle: this.context.userid,
      comment: this.state.comment,
      vendorid: this.context.vendorid,
      invitationid: this.state.invitationid,
    };
    API.post("/saveComment", body).then((response) => {
      if (response.status === 200 && response.data.retType == 0) {
        message.success("Амжилттай илгээгдлээ.");
        this.setState((prevState) => ({
          commentlist: [...this.state.commentlist, body],
          defaultActiveKey: 2,
          defaultActiveKey2: 3,
          dkey: prevState.dkey + 1,
        }));
      }
    });
  };
  onChangeStage = (e) => {
    if (e != 0) {
      this.setState({ stage: e });
    } else {
      let stage = this.state.stage;
      this.setState({ stage: stage + 1 });
    }
  };
  popConfirm = () => {
    return (
      <PopConfirm
        question={
          this.state.isEdit === "true"
            ? "Та тендерийн материал илгээхдээ итгэлтэй байнуу?"
            : "Таны илгээсэн тендер буцаагдах бөгөөд та тендерээ засаад илгээх товч дээр даран дахин илгээх ёстойг анхаарна уу."
        }
        onClickYes={this.onSendTenderMaterial}
        onClickNo={() => this.onChangePopConfirmVisible(false)}
      />
    );
  };
  onChangePopConfirmVisible = (visible) => {
    this.setState({ popConfirmVisible: visible });
  };
  onSendTenderMaterial = () => {
    this.setState({ popConfirmVisible: false });
    if (this.state.isEdit === "true") {
      let body = {
        tenderid: this.state.tenderid,
        invitationid: this.state.invitationid,
        vendorid: this.context.vendorid,
        createdby: this.context.userid,
      };
      API.post("/insertInvitationOfTender", body).then((response) => {
        if (response.status === 200 && response.data.retType === 0) {
          message.success(
            this.state.tendercode + " дугаартай тендер амжилттай илгээгдлээ."
          );
          this.props.history.push("/tenderactivelist");
        }
      });
    } else {
      if (
        moment(this.state.acceptdate, dateFormat) >
        moment(new Date(), dateFormat)
      ) {
        API.get(
          "/updateInvitationOfTender?invitationid=" +
            this.state.invitationid +
            "&vendorid=" +
            this.context.vendorid
        ).then((response) => {
          if (response.status === 200 && response.data.retType === 0) {
            this.setState({ isEdit: "true" });
          }
        });
      } else {
        message.warning(
          "Тендер хүлээн авах хугацаа дууссан тул засах боломжгүй."
        );
      }
    }
  };
  render() {
    return (
      <div>
        <div style={{ backgroundColor: "white" }}>
          <div className="mainContainer">
            <div className="HeaderLabel" style={{ marginBottom: 15 }}>
              ТЕНДЕРТ ОРОЛЦОХ | {this.state.tendername} (
              {this.state.invitationcode})
            </div>
            <Row gutter={[16, 16]}>
              <Col sm={16} md={8}>
                <div className="Label" style={{ marginBottom: 5 }}>
                  Тендер шалгаруулалтын код
                </div>
                <Input disabled size="middle" value={this.state.tendercode} />
              </Col>
              <Col sm={16} md={8}>
                <div className="Label" style={{ marginBottom: 5 }}>
                  Төрөл
                </div>
                <Input
                  disabled
                  size="middle"
                  value={this.state.tendertypename}
                />
              </Col>
              <Col sm={16} md={8}>
                <div className="Label" style={{ marginBottom: 5 }}>
                  Хүлээн авах эцсийн хугацаа
                </div>
                <Input disabled size="middle" value={this.state.acceptdate} />
              </Col>
            </Row>
          </div>
        </div>
        {this.state.isEdit == "true" ? (
          <div>
            <div style={{ backgroundColor: "white" }}>
              <div className="mainContainer">
                <Row gutter={(16, 16)} justify="start" align="middle">
                  <Col span={2.2}>
                    <Row justify="start" align="middle">
                      <Col>
                        <div className="stageCircle">
                          <ButtonStage
                            onClick={() => this.onChangeStage(1)}
                            value={
                              this.state.stage === 1 ? 1 : <StageSuccess />
                            }
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="stageLabel">Баримт бичиг</div>
                      </Col>
                    </Row>
                  </Col>
                  <Col style={{ marginLeft: -10, marginTop: 3 }} span={12}>
                    <div
                      className="stageBorder"
                      style={{
                        borderColor:
                          this.state.stage > 1 ? "#0358A7" : "#E5E5E5",
                      }}
                    ></div>
                  </Col>
                  <Col span={2.2}>
                    <Row justify="start" align="middle">
                      <Col>
                        <div className="stageCircle">
                          <ButtonStage
                            onClick={() => this.onChangeStage(2)}
                            value={this.state.stage > 2 ? <StageSuccess /> : 2}
                            disabled={this.state.stage < 2 ? true : false}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="stageLabel">Нийт үнийн санал</div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="mainContainer">
              {this.state.stage === 1 ? (
                <DocumentList
                  invitationid={this.state.invitationid}
                  tenderid={this.state.tenderid}
                  acceptdate={this.state.acceptdate}
                  isEdit={true}
                />
              ) : (
                <QouteList
                  invitationid={this.state.invitationid}
                  tenderid={this.state.tenderid}
                  acceptdate={this.state.acceptdate}
                  isEdit={true}
                />
              )}
              {this.state.stage === 2 ? (
                <Row justify="start" align="middle">
                  {/* <Col>
                    <Button
                      type="default"
                      style={{
                        fontWeight: 600,
                        fontSize: 14,
                        color: "#212121",
                        borderRadius: 2,
                        marginTop: 20,
                        marginRight: 10,
                      }}
                      htmlType="submit"
                      onClick={() => this.onChangeStage(0)}
                    >
                      Хадгалах
                    </Button>
                  </Col> */}
                  <Col>
                    <Popover
                      placement="bottomRight"
                      title="Санамж"
                      content={this.popConfirm}
                      trigger="click"
                      visible={this.state.popConfirmVisible}
                    >
                      <Button
                        type="primary"
                        style={{
                          backgroundColor: "#0358A7",
                          borderRadius: 4,
                          marginTop: 20,
                        }}
                        htmlType="submit"
                        onClick={() => this.onChangePopConfirmVisible(true)}
                      >
                        Илгээх
                      </Button>
                    </Popover>
                  </Col>
                </Row>
              ) : (
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "#0358A7",
                    borderRadius: 4,
                    marginTop: 20,
                  }}
                  htmlType="submit"
                  onClick={() => this.onChangeStage(0)}
                >
                  Дараагийн алхам
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className="mainContainer">
              <Row justify="end">
                <Popover
                  placement="bottomRight"
                  title="Санамж"
                  content={this.popConfirm}
                  trigger="click"
                  visible={this.state.popConfirmVisible}
                >
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#0358A7",
                      borderRadius: 4,
                    }}
                    htmlType="submit"
                    onClick={() => this.onChangePopConfirmVisible(true)}
                  >
                    Тендер засах
                  </Button>
                </Popover>
              </Row>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Баримт бичиг" key="1">
                  <DocumentList
                    invitationid={this.state.invitationid}
                    tenderid={this.state.tenderid}
                    isEdit={false}
                  />
                </TabPane>
                <TabPane tab="Нийт үнийн санал" key="2">
                  <QouteList
                    invitationid={this.state.invitationid}
                    tenderid={this.state.tenderid}
                    isEdit={false}
                  />
                </TabPane>
                <TabPane tab="Захиа" key={3}>
                  <div
                    style={{
                      padding: 10,
                      backgroundColor: "#fff",
                    }}
                  >
                    <div style={{ overflow: "scroll" }}>
                      {this.state.commentlist.length > 0 ? (
                        <div className="chatbotContent" style={{ height: 280 }}>
                          {this.state.commentlist.map((el) => {
                            return (
                              <div>
                                <Row justify="start">
                                  <Col>
                                    <div className="Label">
                                      {el.commenttitle}
                                    </div>
                                  </Col>
                                  <Col>
                                    <div
                                      style={{
                                        marginLeft: 10,
                                        color: "#636363",
                                      }}
                                    >
                                      {el.commentdate}
                                    </div>
                                  </Col>
                                </Row>
                                <Row justify="start">
                                  <Col>
                                    <div className="chatbox">{el.comment}</div>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <TextArea
                        placeholder="Захиа бичих"
                        onChange={(e) => this.onChangeComment(e.target.value)}
                        defaultValue={this.state.comment}
                        rows={3}
                      />
                    </div>
                    <Row justify="end">
                      <Col>
                        <Button
                          type="primary"
                          style={{
                            backgroundColor: "#0358A7",
                            borderRadius: 2,
                            height: 32,
                            marginTop: 15,
                          }}
                          icon={<SendIcon />}
                          htmlType="submit"
                          onClick={this.onSendComment}
                        >
                          Илгээх
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(TenderJoin);
