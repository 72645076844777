import React, { Component } from "react";
import { Row, Col, Table, Drawer, Button, message, InputNumber, Typography } from "antd";
import { withRouter } from "react-router-dom";
import "./Qoute.css";
import QouteAdd from "./QouteAdd";
import PlusIcon from "../../../../svg/svgicon/PlusIcon";
import ButtonLinkIcon from "../../../../Components/MyComponent/ButtonLinkIcon";
import { API } from "../../../../API/API";
import UserContext from "../../../UserContext/UserContext";
import NumberFormat from "react-number-format";
const { Text } = Typography;
const dateFormat = "YYYY.MM.DD HH:mm";
var moment = require("moment");
class QouteList extends Component {
  static contextType = UserContext;
  state = {
    data: [],
    showNewInputForm: false,
    selectedQouteID: 0,
    tenderid: this.props.tenderid,
    invitationid: this.props.invitationid,
    acceptdate: this.props.acceptdate
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    API.get(
      "/getQouteList?invitationid=" +
        this.props.invitationid +
        "&vendorid=" +
        this.context.vendorid
    ).then((response) => {
      if (response.status === 200 && response.data.retType === 0) {
        this.setState({
          data: response.data.retData,
        });
      }
    });
  };
  onDeleteRow = (qouteid) => {
    API.get("/deleteQoute?qouteid=" + qouteid).then((response) => {
      if (response.status === 200 && response.data.retType === 0) {
        message.success("Амжилттай устгалаа.");
        this.getData();
      }
    });
  };
  showNewInputFormHandler = (show, qouteid) => {
    this.setState({
      showNewInputForm: show,
      selectedQouteID: qouteid,
    });
    if (!show) {
      this.getData();
    }
  };
  render() {
    const columns = [
      {
        title: "Тендер шалгаруулалтын код",
        dataIndex: "tendercode",
        key: "tendercode",
        width: "150px",
        align: "left",
        responsive: ["lg"],
        render: (text, record) => {
          return (
            <Button
              style={{ fontWeight: "500", color: "#0358A7" }}
              type="link"
              onClick={() =>
                moment(this.state.acceptdate, dateFormat) >
                moment(new Date(), dateFormat) ? this.showNewInputFormHandler(true, record.qouteid) : null
              }
            >
              {record.tendercode}
            </Button>
          );
        },
      },
      {
        title: "Тендер шалгаруулалтын нэр",
        dataIndex: "tendername",
        key: "tendername",
        width: "auto",
        align: "left",
        responsive: ["lg"],
      },
      {
        title: "Хүчинтэй хугацаа",
        dataIndex: "qoutedate",
        key: "qoutedate",
        width: "130px",
        align: "left",
      },
      {
        title: "Бараа нийлүүлэх болон ажил гүйцэтгэх эцсийн хугацаа",
        dataIndex: "deliverydate",
        key: "deliverydate",
        width: "20%",
        align: "left",
      },
      {
        title: "Багц",
        dataIndex: "batchname",
        key: "batchname",
        width: "15%",
        align: "left",
      },
      {
        title: "Тендерийн нийт үнэ",
        dataIndex: "qouteamount",
        key: "qouteamount",
        width: "130px",
        align: "left",
        render: (text, record) => {
          return (
            <InputNumber
              placeholder="___._"
              style={{ width: "100%", border: "none" }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              align="right"
              value={record.qouteamount}
              disabled
            />
          );
        },
      },
      {
        title: this.props.isEdit === true ? "Үйлдэл" : null,
        dataIndex: "action",
        key: "action",
        width: "80px",
        align: "left",
        render: (text, record) => {
          return this.props.isEdit === true ? (
            // <Row justify="start">
              <Button
                type="link"
                style={{ fontWeight: "500", color: "#0358A7" }}
                onClick={() => this.onDeleteRow(record.qouteid)}
              >
                Устгах
              </Button>
            // </Row>
          ) : null;
        },
      },
    ];
    return (
      <div className="Container">
        <Row align="middle">
          <Col span={24}>
            <Row style={{ height: 56 }} justify="start" align="middle">
              <Col xs={12} sm={12} md={10}>
                <div className="HeaderLabel">2. Нийт үнийн санал</div>
              </Col>
              <Col>
                <div className="divider"></div>
              </Col>
              {this.props.isEdit === true ? (
                <Col span={5}>
                  <ButtonLinkIcon
                    onClick={() => this.showNewInputFormHandler(true, 0)}
                    icon={<PlusIcon />}
                    name="Үнийн санал оруулах"
                  />
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>

        <div id="qoute">
          <Table
            tableLayout="fixed"
            size="small"
            dataSource={this.state.data}
            scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }} 
            
            columns={[
              {
                title: "№",
                dataIndex: "key",
                key: "key",
                width: "30px",
                render: (text, row, index) => (
                  <>{this.state.data.indexOf(row) + 1}</>
                ),
              },
              ...columns,
            ]}
            pagination={false}
            rowKey="qouteid"
            summary={(pageData) => {
              let totalAmount = 0;

              pageData.forEach(
                ({
                  qouteamount
                }) => {
                  totalAmount += Number(qouteamount);
                }
              );
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell ></Table.Summary.Cell>
                    <Table.Summary.Cell ></Table.Summary.Cell>
                    <Table.Summary.Cell ></Table.Summary.Cell>
                    <Table.Summary.Cell ></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text style={{ fontWeight: 600 }}>
                        <InputNumber
                          style={{ width: "100%", border: "none"}}
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          align="right"
                          value={totalAmount}
                        />
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
        <Drawer
          title="Үнийн санал оруулах"
          width="500px"
          placement="right"
          visible={this.state.showNewInputForm}
          onClose={() => this.showNewInputFormHandler(false, 0)}
          destroyOnClose={true}
          style
        >
          <QouteAdd
            showForm={this.showNewInputFormHandler}
            qouteid={this.state.selectedQouteID}
            invitationid={this.state.invitationid}
            tenderid={this.state.tenderid}
          />
        </Drawer>
      </div>
    );
  }
}

export default withRouter(QouteList);
