import React from "react";
const PlusIcon = (props) => {
  return (
    <svg
      style={{ marginRight: 5 }}
      width="14.59"
      height="14.58"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.7915 0.5C8.10791 0.5 8.3695 0.735008 8.41091 1.04007L8.41666 1.12488L8.41766 7.16667H14.4619C14.8071 7.16667 15.0869 7.4465 15.0869 7.79167C15.0869 8.10808 14.8517 8.36958 14.5467 8.411L14.4619 8.41667H8.41766L8.41933 14.4576C8.41941 14.8028 8.13966 15.0828 7.7945 15.0828C7.47808 15.0828 7.2165 14.8478 7.17508 14.5427L7.16933 14.4578L7.16766 8.41667H1.12683C0.781648 8.41667 0.501831 8.13683 0.501831 7.79167C0.501831 7.47525 0.736956 7.21375 1.04202 7.17233L1.12683 7.16667H7.16766L7.16666 1.12512C7.16658 0.77995 7.44633 0.5 7.7915 0.5Z"
        fill="#0358A7"
      />
    </svg>
  );
};

export default PlusIcon;
