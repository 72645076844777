import React, { Component } from "react";
import { Row, Col, Tabs, Table, Button, Input, message } from "antd";
import FileDownload from "js-file-download";
import { withRouter } from "react-router-dom";
import "./InvitationList.css";
import RightSide from "../../Components/MyComponent/RightSide";
import DocumentIcon from "../../svg/svgicon/DocumentIcon";
import SendIcon from "../../svg/svgicon/SendIcon";
import { API } from "../../API/API";
const { TabPane } = Tabs;
const { TextArea } = Input;
class InvitationOfTender extends Component {
  state = {
    tendername: this.props.match.params.tendername,
    invitationid: this.props.match.params.invitationid,
    balanceday: "",
    tendercode: "",
    invitationcode: "",
    startdate: "",
    enddate: "",
    departmentname: "",
    tendertypename: "",
    criterialist: [],
    requirelist: [],
    email: "",
    address: "",
    phone: "",
    tenderdoclist: [],
    tenderBatch: [],
    commentlist: [],
    post: "",
    electron: "",
    delaynote: "",
    dkey: 1,
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    API.get("/getInvitationDtl?invitationid=" + this.state.invitationid).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.retData.length > 0) {
            this.setState((prevState) => ({
              tendername: response.data.retData[0][0].tendername,
              departmentname: response.data.retData[0][0].departmentname,
              tendertypename: response.data.retData[0][0].tendertypename,
              tendercode: response.data.retData[0][0].tendercode,
              invitationcode: response.data.retData[0][0].invitationcode,
              description: response.data.retData[0][0].description,
              startdate: response.data.retData[0][0].startdate,
              enddate: response.data.retData[0][0].enddate,
              balanceday: response.data.retData[0][0].balanceday,
              delaynote: response.data.retData[0][0].delaynote,
              criterialist: response.data.retData[1],
              requirelist: response.data.retData[2],
              address: response.data.retData[3][0].address,
              email: response.data.retData[3][0].email,
              phone: response.data.retData[3][0].phone,
              electron:
                response.data.retData[3][0].electron === 1 ? "Цахим" : "",
              post:
                response.data.retData[3][0].post === 1 ? "Хаягаар ирүүлэх" : "",
              tenderdoclist: response.data.retData[4],
              tenderBatch: response.data.retData[5],
              commentlist: response.data.retData[6],
              dkey: prevState.dkey + 1,
            }));
          }
        }
      }
    );
  };
  onDownLoadFile = (sourceid, sourcetype, filename) => {
    console.log(sourceid, sourcetype, filename);
    API.get("/download?sourceid=" + sourceid + "&sourcetype=" + sourcetype, {
      responseType: "blob",
    }).then((response) => {
      if (response.status === 200) {
        console.log(response);
        FileDownload(response.data, filename);
      }
    });
  };
  render() {
    const columnDocs = [
      {
        title: "Баримт бичгийн төрөл",
        dataIndex: "doctypename",
        key: "doctypename",
        width: "25%",
        align: "left",
      },
      {
        title: "Баримт бичиг",
        dataIndex: "documentname",
        key: "documentname",
        width: "25%",
        align: "left",
        render: (text, record) => {
          return (
            <Button
              style={{ fontWeight: "500", color: "#0358A7" }}
              type="link"
              onClick={() =>
                this.onDownLoadFile(
                  record.sourceid,
                  record.sourcetype,
                  record.filename
                )
              }
            >
              {record.documentname}
            </Button>
          );
        },
      },
      {
        title: "Багцын код",
        dataIndex: "batchcode",
        key: "batchcode",
        width: "15%",
        align: "left",
      },
      {
        title: "Багцын нэр",
        dataIndex: "batchname",
        key: "batchname",
        width: "15%",
        align: "left",
      },
      {
        title: "Үүсгэсэн огноо",
        dataIndex: "created",
        key: "created",
        width: "25%",
        align: "left",
      },
    ];
    const columnBatch = [
      {
        title: "Багцын код",
        dataIndex: "batchcode",
        key: "batchcode",
        width: "45%",
        align: "left",
      },
      {
        title: "Багцын нэр",
        dataIndex: "batchname",
        key: "batchname",
        width: "45%",
        align: "left",
      },
    ];
    const columnCriteria = [
      {
        title: "Шалгуурын төрөл",
        dataIndex: "criteriatypename",
        key: "criteriatypename",
        width: "45%",
        align: "left",
      },
      {
        title: "Нэмэлт тайлбар",
        dataIndex: "criterianame",
        key: "criterianame",
        width: "45%",
        align: "left",
      },
    ];
    return (
      <div id="invitationOfTender" key={this.state.dkey}>
        <div style={{ paddingRight: 120, paddingLeft: 100 }}>
          <Row gutter={(16, 16)}>
            <Col span={17}>
              <div style={{ backgroundColor: "#fff" }}>
                <div style={{ padding: 20 }}>
                  <div className="HeaderLabel" style={{ marginBottom: 15 }}>
                    {this.state.tendername}
                  </div>
                  <div>
                    <Row gutter={(16, 16)}>
                      <Col className="headerTable-leftSide" span={12}>
                        <div className="headerTable-leftSide-Text">
                          Захиалагч:
                        </div>
                      </Col>
                      <Col className="headerTable-rightSide" span={12}>
                        <div className="headerTable-rightSide-Text">
                          Монголын алт ХХК - Хангамж техник үйлчилгэээний газар
                        </div>
                      </Col>
                      <Col className="headerTable-leftSide" span={12}>
                        <div className="headerTable-leftSide-Text">
                          Тендер шалгаруулалтын төрөл:
                        </div>
                      </Col>
                      <Col className="headerTable-rightSide" span={12}>
                        <div className="headerTable-rightSide-Text">
                          {this.state.tendertypename}
                        </div>
                      </Col>
                      <Col className="headerTable-leftSide" span={12}>
                        <div className="headerTable-leftSide-Text">
                          Тендер шалгаруулалтын дугаар:
                        </div>
                      </Col>
                      <Col className="headerTable-rightSide" span={12}>
                        <div className="headerTable-rightSide-Text">
                          {this.state.tendercode}
                        </div>
                      </Col>
                      <Col className="headerTable-leftSide" span={12}>
                        <div className="headerTable-leftSide-Text">
                          Урилгын дугаар:
                        </div>
                      </Col>
                      <Col className="headerTable-rightSide" span={12}>
                        <div className="headerTable-rightSide-Text">
                          {this.state.invitationcode}
                        </div>
                      </Col>
                    </Row>
                    {this.state.delaynote ? (
                      <div>
                        <div
                          className="content-Text-Bold"
                          style={{ marginTop: 15 }}
                        >
                          Хойшлуулсан:
                        </div>
                        <div
                          className="content-Text"
                          style={{ marginLeft: 10, color: "red" }}
                        >
                          /{this.state.enddate + " хүртэл "}/{" "}
                          {this.state.delaynote}
                        </div>
                      </div>
                    ) : null}
                    <div className="content-Text" style={{ marginTop: 15 }}>
                      {this.state.description}
                    </div>
                    <div
                      className="content-Text-Bold"
                      style={{ marginTop: 15 }}
                    >
                      КОМПАНИД ТАВИГДАХ ШААРДЛАГА
                    </div>
                    {this.state.criterialist.map((el, index) => (
                      <div
                        key={index}
                        className="content-Text"
                        style={{ marginLeft: 10 }}
                      >
                        {index + 1}. {el.criteriatypename}
                      </div>
                    ))}
                    <div
                      className="content-Text-Bold"
                      style={{ marginTop: 15 }}
                    >
                      БҮРДҮҮЛЭХ МАТЕРИАЛЫН ЖАГСААЛТ
                    </div>
                    {this.state.requirelist.map((el, index) => (
                      <div
                        key={index}
                        className="content-Text"
                        style={{ marginLeft: 10 }}
                      >
                        {index + 1}. {el.requirevalue}
                      </div>
                    ))}
                    <div style={{ marginTop: 20 }}>
                      <text style={{ fontWeight: 700 }}>
                        Хамтран ажиллах саналын материалыг
                      </text>{" "}
                      {this.state.enddate} хүртэл{" "}
                      {this.state.electron !== "" && this.state.post !== ""
                        ? this.state.electron +
                          " болон " +
                          this.state.post +
                          " хэлбэрээр "
                        : this.state.electron !== "" || this.state.post !== ""
                        ? this.state.electron +
                          " " +
                          this.state.post +
                          " хэлбэрээр "
                        : null}{" "}
                      хүлээн авна.
                    </div>
                    <div
                      className="content-Text-Bold"
                      style={{ marginTop: 15 }}
                    >
                      Хаяг:{" "}
                      <text style={{ fontWeight: 400 }}>
                        {this.state.address}
                      </text>
                    </div>
                    <div
                      className="content-Text-Bold"
                      style={{ marginTop: 15 }}
                    >
                      И-мэйл:{" "}
                      <text style={{ fontWeight: 400 }}>
                        {this.state.email}
                      </text>
                    </div>
                    <div
                      className="content-Text-Bold"
                      style={{ marginTop: 15 }}
                    >
                      Вэб сайт:{" "}
                      <text style={{ fontWeight: 400 }}>www.mak.mn</text>
                    </div>
                    <div
                      className="content-Text-Bold"
                      style={{ marginTop: 15 }}
                    >
                      Утас:{" "}
                      <text style={{ fontWeight: 400 }}>
                        {this.state.phone}
                      </text>
                    </div>
                    <Row
                      className="documentHeader"
                      justify="start"
                      align="middle"
                    >
                      <Col>
                        <DocumentIcon />
                      </Col>
                      <Col>
                        <div
                          className="documentHeader-Text"
                          style={{ marginLeft: 10 }}
                        >
                          Холбогдох бичиг баримтууд
                        </div>
                      </Col>
                    </Row>
                    <div>
                      <Tabs defaultActiveKey="1">
                        <TabPane tab="Нэмэлт баримт бичиг" key="1">
                          <div id="tender-list" style={{ marginRight: 15 }}>
                            <Table
                              dataSource={this.state.tenderdoclist}
                              columns={columnDocs}
                              pagination={false}
                              rowKey="docid"
                            />
                          </div>
                        </TabPane>
                        <TabPane tab="Тодруулга" key="2">
                          <div id="tender-list" style={{ marginRight: 15 }}>
                            <Table
                              dataSource={this.state.criterialist}
                              columns={columnCriteria}
                              pagination={false}
                              rowKey="docid"
                            />
                          </div>
                        </TabPane>
                        <TabPane tab="Захиа" key="3">
                          <div style={{ overflow: "scroll" }}>
                            {this.state.commentlist.length > 0 ? (
                              <div
                                className="chatbotContent"
                                style={{ height: 280 }}
                              >
                                {this.state.commentlist.map((el) => {
                                  return (
                                    <div style={{ padding: 10 }}>
                                      <Row justify="start">
                                        <Col>
                                          <div className="Label">
                                            {el.commenttitle}
                                          </div>
                                        </Col>
                                        <Col>
                                          <div
                                            style={{
                                              marginLeft: 10,
                                              color: "#636363",
                                            }}
                                          >
                                            {el.commentdate}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row justify="start">
                                        <Col>
                                          <div className="chatbox">
                                            {el.comment}
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : null}
                          </div>
                          <div style={{ marginTop: 10 }}>
                            <TextArea
                              placeholder="Захиа бичих"
                              onChange={this.onChangeClarificationText}
                              rows={3}
                            />
                          </div>
                          <Row justify="end">
                            <Col>
                              <Button
                                type="primary"
                                style={{
                                  backgroundColor: "#0358A7",
                                  borderRadius: 2,
                                  height: 32,
                                  marginTop: 15,
                                }}
                                icon={<SendIcon />}
                                htmlType="submit"
                                onClick={() =>
                                  message.warning(
                                    "Та нэвтэрч байж захиа илгээх боломжтой."
                                  )
                                }
                              >
                                Илгээх
                              </Button>
                            </Col>
                          </Row>
                        </TabPane>
                      </Tabs>
                    </div>
                    <Row
                      className="documentHeader"
                      justify="start"
                      align="middle"
                    >
                      <Col>
                        <DocumentIcon />
                      </Col>
                      <Col>
                        <div
                          className="documentHeader-Text"
                          style={{ marginLeft: 10 }}
                        >
                          Тендерийн багц
                        </div>
                      </Col>
                    </Row>
                    <div id="tender-list" style={{ marginRight: 15 }}>
                      <Table
                        dataSource={this.state.tenderBatch}
                        columns={columnBatch}
                        pagination={false}
                        rowKey="docid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={7}>
              <RightSide
                startdate={this.state.startdate}
                enddate={this.state.enddate}
                enddateLabel="Хүлээн авах эцсийн хугацаа"
                balanceday={this.state.balanceday}
                buttonText="Тендерт оролцох"
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(InvitationOfTender);
