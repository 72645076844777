import React from "react";

const homeIcon = (props) => {
  return (
    <svg
      style={{ marginRight: 7 }}
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 0C11.2239 0 11 0.22386 11 0.5C11 0.77614 11.2239 1 11.5 1H13.2929L8.5 5.79289L5.85355 3.14645C5.65829 2.95118 5.34171 2.95118 5.14645 3.14645L0.146452 8.1464C-0.0488175 8.3417 -0.0488175 8.6583 0.146452 8.8536C0.341712 9.0488 0.658292 9.0488 0.853552 8.8536L5.5 4.20711L8.1464 6.85355C8.3417 7.04882 8.6583 7.04882 8.8536 6.85355L14 1.70711V3.5C14 3.77614 14.2239 4 14.5 4C14.7761 4 15 3.77614 15 3.5V0.5C15 0.22386 14.7761 0 14.5 0H11.5ZM12.5 7C12.2239 7 12 7.22386 12 7.5V15.5C12 15.7761 12.2239 16 12.5 16C12.7761 16 13 15.7761 13 15.5V7.5C13 7.22386 12.7761 7 12.5 7ZM4.5 9C4.22386 9 4 9.2239 4 9.5V15.5C4 15.7761 4.22386 16 4.5 16C4.77614 16 5 15.7761 5 15.5V9.5C5 9.2239 4.77614 9 4.5 9ZM8 11.5C8 11.2239 8.2239 11 8.5 11C8.7761 11 9 11.2239 9 11.5V15.5C9 15.7761 8.7761 16 8.5 16C8.2239 16 8 15.7761 8 15.5V11.5ZM2.45869e-06 12.5C2.45869e-06 12.2239 0.223862 12 0.500002 12C0.776142 12 1 12.2239 1 12.5V15.5C1 15.7761 0.776142 16 0.500002 16C0.223862 16 2.45869e-06 15.7761 2.45869e-06 15.5V12.5Z"
        fill="#292D37"
      />
    </svg>
  );
};

export default homeIcon;
