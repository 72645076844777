import React from "react";
import { Button } from "antd";

const ButtonLinkIcon = (props) => {
  return (
    <Button
      style={{
        color: "#212121",
        fontSize: "14px",
        fontWeight: "400",
      }}
      type="link"
      onClick={props.onClick}
      icon={props.icon}
    >
      {props.name}
    </Button>
  );
};

export default ButtonLinkIcon;
