import React, { Component } from "react";
import { Row, Col, Button } from "antd";
import { withRouter } from "react-router-dom";
import "./RightSide.css";
import DateIcon from "../../svg/svgicon/DateIcon";
class RightSide extends Component {
  state = {
    startdate: this.props.startdate,
    enddate: this.props.enddate,
    balanceday: this.props.balanceday,
  };
  onClick = () => {
    this.props.history.push("/login");
  };
  render() {
    return (
      <div className="RightSide">
        <Row gutter={(16, 16)} align="middle">
          {this.state.startdate ? (
            <Col style={{ marginBottom: -15 }} span={24}>
              <div className="RightSide-Container">
                <Row justify="start" align="middle">
                  <Col>
                    <div style={{ padding: 10 }}>
                      <DateIcon />
                    </div>
                  </Col>

                  <Col>
                    <Row gutter={(16, 16)}>
                      <Col span={24}>
                        <div className="right-side-label">
                          Тендер зарласан хугацаа:
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="right-side-label-bold">
                          {this.state.startdate}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          ) : null}
          <Col span={24}>
            <div className="RightSide-Container">
              <Row justify="start" align="middle">
                <Col>
                  <div style={{ padding: 10 }}>
                    <DateIcon />
                  </div>
                </Col>
                <Col>
                  <Row>
                    <Col span={24}>
                      <div className="right-side-label">
                        {this.props.enddateLabel}
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="right-side-label-bold">
                        {this.state.enddate}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col style={{ paddingRight: 20, paddingLeft: 20 }} span={24}>
            <Button
              style={{
                color: "#fff",
                backgroundColor: "#F7941D",
                height: 40,
                width: "100%",
                border: "none",
              }}
              onClick={this.onClick}
            >
              {this.props.buttonText}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(RightSide);
