import React from "react";

const TenderJoinedIcon = (props) => {
  return (
    <svg
      style={{ marginRight: 7 }}
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1501 1.06694C17.3942 0.822865 17.3942 0.427131 17.1501 0.183056C16.906 -0.0610188 16.5103 -0.0610188 16.2663 0.183056L14.6248 1.82445L14.2334 1.43306C13.9893 1.18898 13.5937 1.18898 13.3496 1.43306C13.1055 1.67713 13.1055 2.07286 13.3496 2.31694L14.1829 3.15027C14.427 3.39435 14.8227 3.39435 15.0668 3.15027L17.1501 1.06694ZM10.8748 12.089H1.2915L1.2067 12.0947C0.901629 12.1362 0.666504 12.3977 0.666504 12.714C0.666504 13.0592 0.946329 13.339 1.2915 13.339H10.8748L10.9597 13.3333C11.2647 13.2919 11.4998 13.0304 11.4998 12.714C11.4998 12.3688 11.22 12.089 10.8748 12.089ZM10.8748 6.66666H1.2915L1.2067 6.67233C0.901629 6.71375 0.666504 6.97525 0.666504 7.29166C0.666504 7.63683 0.946329 7.91666 1.2915 7.91666H10.8748L10.9597 7.911C11.2647 7.86958 11.4998 7.60808 11.4998 7.29166C11.4998 6.9465 11.22 6.66666 10.8748 6.66666ZM10.8748 1.25H1.2915L1.2067 1.25571C0.901629 1.29709 0.666504 1.55858 0.666504 1.875C0.666504 2.22017 0.946329 2.5 1.2915 2.5H10.8748L10.9597 2.49429C11.2647 2.45291 11.4998 2.19141 11.4998 1.875C11.4998 1.52982 11.22 1.25 10.8748 1.25ZM17.1501 11.9002C17.3942 11.6562 17.3942 11.2605 17.1501 11.0164C16.906 10.7723 16.5103 10.7723 16.2663 11.0164L14.6248 12.6577L14.2334 12.2664C13.9893 12.0223 13.5937 12.0223 13.3496 12.2664C13.1055 12.5105 13.1055 12.9062 13.3496 13.1502L14.1829 13.9836C14.427 14.2277 14.8227 14.2277 15.0668 13.9836L17.1501 11.9002ZM17.1501 5.59975C17.3942 5.84383 17.3942 6.2395 17.1501 6.48358L15.0668 8.56691C14.8227 8.811 14.427 8.811 14.1829 8.56691L13.3496 7.73358C13.1055 7.4895 13.1055 7.09383 13.3496 6.84975C13.5937 6.60566 13.9893 6.60566 14.2334 6.84975L14.6248 7.24108L16.2663 5.59975C16.5103 5.35565 16.906 5.35565 17.1501 5.59975Z"
        fill="#605E5C"
      />
    </svg>
  );
};

export default TenderJoinedIcon;
