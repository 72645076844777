import axios from "axios";
import { message } from "antd";
import {
  showWaitScreen,
  hideLoadScreen,
  showLoadScreen,
} from "../Components/LoadScreen/LoadScreen";
import { baseConfig } from "../Config/Config";

let apiBaseUrl = baseConfig.baseurl;
let errorMessage = "Амжилтгүй боллоо. ";

const auth = {
  "Access-Control-Allow-Origin": "*",
};

const API = axios.create({
  baseURL: apiBaseUrl + "/api/maktender/",
  withCredentials: false,
  timeout: 240000,
  headers: auth,
});

API.interceptors.request.use(
  function (config) {
    showLoadScreen();
    let token = localStorage.getItem("access_token");
    if (config.headers && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    message.error(errorMessage + error.message);
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    hideLoadScreen();
    if (response.status !== 200) {
      message.error(errorMessage + response.message);
    }
    if (
      response.config.responseType !== "blob" &&
      response.data.retType !== 0
    ) {
      message.error(errorMessage + response.data.retMsg);
    }
    return response;
  },
  function (error) {
    hideLoadScreen();
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401 &&
      error.response.config.url.indexOf("api/tenderauth/login") >= 0
    ) {
      message.error("Нэвтрэх нэр эсвэл нууц үг буруу байна!");
    } else {
      message.error(errorMessage + error.message);
    }
    return Promise.reject(error);
  }
);

export { API };
