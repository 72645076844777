import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Layout } from "antd";
import "./App.css";
import Header from "./Page/Header/Header";
import Header2 from "./Page/Header/Header2";
import Home from "./Page/Home/Home";
import Login from "./Page/Login/Login";
import Register from "./Page/Login/Register";
import ResetPwd from "./Page/Login/ResetPwd";
import CompanyInfo from "./Page/CompanyInfo/CompanyInfo";
import InvitationOfTenderList from "./Page/InvitationOfTender/InvitationOfTenderList";
import InvitationOfTender from "./Page/InvitationOfTender/InvitationOfTender";
import PlanList from "./Page/PlanList/PlanList";
import JoinWorkOfVendorList from "./Page/JoinWorkOfVendorList/JoinWorkOfVendorList";
import JoinWorkOfVendorDtl from "./Page/JoinWorkOfVendorList/JoinWorkOfVendorDtl";
import TenderPossibleList from "./Page/Tender/TenderPossibleList/TenderPossibleList";
import TenderPossibleDtl from "./Page/Tender/TenderPossibleDtl/TenderPossibleDtl";
import TenderJoin from "./Page/Tender/TenderJoin/TenderJoin";
import TenderActiveList from "./Page/Tender/TenderActive/TenderActiveList";
import TenderInvolved from "./Page/Tender/TenderInvolved/TenderInvolved";
import JoinWorkList from "./Page/JoinWork/JoinWorkList";
import JoinWorkDtl from "./Page/JoinWork/JoinWorkDtl";
import JoinWorkRequest from "./Page/JoinWork/JoinWorkRequest";
import JoinWorkInvolved from "./Page/JoinWork/JoinWorkInvolved";
import MenuComponent from "./Components/MenuComponent/MenuComponent";
import { LoadScreen } from "./Components/LoadScreen/LoadScreen";
import UserContext from "./Page/UserContext/UserContext";
const { Content, Sider } = Layout;
class App extends Component {
  state = {
    userid: null,
    basecompany: null,
    sMenu: 0,
    isAuth: false,
  };

  changeSMenu = (sMenu) => {
    this.setState({
      sMenu: sMenu,
    });
  };

  successLogin = (userid, basecompany, vendorid) => {
    localStorage.setItem("userid", userid);
    localStorage.setItem("basecompany", basecompany);
    localStorage.setItem("vendorid", vendorid);

    this.setState({
      isAuth: true,
      userid: userid,
      basecompany: basecompany,
      vendorid: vendorid,
    });
  };

  logOut = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("basecompany");

    this.setState({
      isAuth: false,
      userid: null,
      basecompany: null,
      vendorid: null,
    });
  };

  componentDidMount() {
    let expires_at = localStorage.getItem("expires_at");
    let token = localStorage.getItem("access_token");
    let eDate = new Date(expires_at);
    let vToday = new Date();
    if (token) {
      let userid = localStorage.getItem("userid");
      let basecompany = localStorage.getItem("basecompany");
      let vendorid = localStorage.getItem("vendorid");
      if (userid && basecompany) {
        this.setState({
          isAuth: true,
          userid: userid,
          basecompany: basecompany,
          vendorid: vendorid,
        });
      }
    }
    else {
      this.logOut();
    }
  }

  render() {
    let v_router = null;

    if (this.state.isAuth) {
      v_router = (
        <div>
          <Header2
            username={this.state.userid}
            sMenu={this.state.sMenu}
            logout={this.logOut}
          ></Header2>
          <div style={{ height: "48px" }}></div>
          <Switch>
            <Layout>
              <Sider style={{ backgroundColor: "#fff" }}
              breakpoint="lg"
              collapsedWidth="0"
              onBreakpoint={(broken) => {
                // console.log(broken);
              }}
              onCollapse={(collapsed, type) => {
                // console.log(collapsed, type);
              }}
              >
                <MenuComponent menuType="user"></MenuComponent>
              </Sider>
              <Content>
                <Route path="/home">
                  <Home></Home>
                </Route>
                <Route path="/companyinfo">
                  <CompanyInfo></CompanyInfo>
                </Route>
                <Route path="/tenderpossiblelist">
                  <TenderPossibleList></TenderPossibleList>
                </Route>
                <Route path="/tenderpossibledtl/:invid/:tenderid">
                  <TenderPossibleDtl></TenderPossibleDtl>
                </Route>
                <Route path="/tenderjoin/:isedit/:invid/:invcode/:tenderid/:tendercode/:tendername/:id/:acceptdate/:tendertypename">
                  <TenderJoin></TenderJoin>
                </Route>
                <Route path="/tenderactivelist">
                  <TenderActiveList></TenderActiveList>
                </Route>
                <Route path="/tenderinvolvedlist">
                  <TenderInvolved></TenderInvolved>
                </Route>
                <Route path="/joinworklist">
                  <JoinWorkList></JoinWorkList>
                </Route>
                <Route path="/joinworkdtl/:joinworkid/:joinworkname/:joinworkcode/:tendertypename">
                  <JoinWorkDtl></JoinWorkDtl>
                </Route>
                <Route path="/joinworkrequest/:joinworkid/:joinworkname/:joinworkcode/:tendertypename/:enddate">
                  <JoinWorkRequest></JoinWorkRequest>
                </Route>
                <Route path="/joinworkinvolved">
                  <JoinWorkInvolved></JoinWorkInvolved>
                </Route>
              </Content>
            </Layout>
          </Switch>
        </div>
      );
    } else {
      v_router = (
        <div>
          <Header userid={this.state.userid} logout={this.logOut}></Header>
          <div style={{ height: "48px" }}></div>
          <Switch>
            <Route path="/invitationoftenderlist">
              {/* <InvitationOfTenderList></InvitationOfTenderList> */}
              <Login successLogin={this.successLogin}></Login>
            </Route>
            <Route path="/invitationoftender/:invitationid/:invitationname">
              <InvitationOfTender></InvitationOfTender>
            </Route>
            <Route path="/planlist">
              <PlanList></PlanList>
            </Route>
            <Route path="/joinworkofvendorlist">
              <JoinWorkOfVendorList></JoinWorkOfVendorList>
            </Route>
            <Route path="/joinworkofvendordtl/:joinworkid/:joinworkname">
              <JoinWorkOfVendorDtl></JoinWorkOfVendorDtl>
            </Route>
            <Route path="/login">
              <Login successLogin={this.successLogin}></Login>
            </Route>
            <Route path="/register">
              <Register></Register>
            </Route>
            <Route path="/resetpwd">
              <ResetPwd></ResetPwd>
            </Route>
            <Route path="/">
              <Login successLogin={this.successLogin}></Login>
              {/* <InvitationOfTenderList></InvitationOfTenderList> */}
            </Route>
          </Switch>
        </div>
      );
    }

    return (
      <div className="App">
        <Router>
          <UserContext.Provider
            value={{
              userid: this.state.userid,
              basecompany: this.state.basecompany,
              vendorid: this.state.vendorid,
            }}
          >
            {v_router}
          </UserContext.Provider>

          <LoadScreen />
        </Router>
      </div>
    );
  }
}

export default App;
