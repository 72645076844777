import React from "react";

const DocumentIcon = (props) => {
  return (
    <div
      style={{
        backgroundColor: "#F7941D",
        padding: 4,
        paddingLeft: 8,
        paddingRight: 8,
      }}
    >
      <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.29183 8.58329C3.94665 8.58329 3.66683 8.86313 3.66683 9.20829C3.66683 9.55346 3.94665 9.83329 4.29183 9.83329H9.7085C10.0537 9.83329 10.3335 9.55346 10.3335 9.20829C10.3335 8.86313 10.0537 8.58329 9.7085 8.58329H4.29183ZM4.29183 10.875C3.94665 10.875 3.66683 11.1548 3.66683 11.5C3.66683 11.8451 3.94665 12.125 4.29183 12.125H9.7085C10.0537 12.125 10.3335 11.8451 10.3335 11.5C10.3335 11.1548 10.0537 10.875 9.7085 10.875H4.29183ZM4.29183 13.1666C3.94665 13.1666 3.66683 13.4465 3.66683 13.7916C3.66683 14.1368 3.94665 14.4166 4.29183 14.4166H9.7085C10.0537 14.4166 10.3335 14.1368 10.3335 13.7916C10.3335 13.4465 10.0537 13.1666 9.7085 13.1666H4.29183ZM8.321 1.15496L13.1785 6.01163C13.491 6.32413 13.6668 6.74829 13.6668 7.18996V15.6666C13.6668 16.5866 12.9202 17.3333 12.0002 17.3333H2.00016C1.08016 17.3333 0.333496 16.5866 0.333496 15.6666V2.33329C0.333496 1.41329 1.08016 0.666626 2.00016 0.666626H7.1435C7.16675 0.666626 7.18941 0.669718 7.212 0.672801C7.22833 0.675026 7.24458 0.677243 7.261 0.678293C7.44016 0.690793 7.61683 0.724959 7.781 0.793293C7.82883 0.813693 7.87416 0.839934 7.91933 0.866068C7.93325 0.874101 7.94708 0.882118 7.961 0.889959C7.97391 0.897018 7.98725 0.903509 8.00058 0.910018C8.02358 0.921193 8.04658 0.932409 8.06766 0.946626C8.13266 0.990793 8.191 1.04329 8.25016 1.09663C8.25975 1.10513 8.27 1.11302 8.28041 1.12096C8.29441 1.13169 8.3085 1.1425 8.321 1.15496ZM12.0002 16.0833C12.2302 16.0833 12.4168 15.8958 12.4168 15.6666V7.33329H8.66683C7.74683 7.33329 7.00016 6.58663 7.00016 5.66663V1.91663H2.00016C1.77016 1.91663 1.5835 2.10413 1.5835 2.33329V15.6666C1.5835 15.8958 1.77016 16.0833 2.00016 16.0833H12.0002ZM11.4818 6.08329L8.25016 2.85079V5.66663C8.25016 5.89579 8.43683 6.08329 8.66683 6.08329H11.4818Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default DocumentIcon;
