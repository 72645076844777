import React from "react";
import { ReactSVG } from "react-svg";
import Path from "react-svg-path";

const BackArrow = (props) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.73272 15.7907C8.03264 16.0764 8.50738 16.0649 8.79306 15.765C9.07875 15.465 9.06721 14.9903 8.76728 14.7046L2.51587 8.75L17.25 8.75C17.6642 8.75 18 8.41421 18 8C18 7.58579 17.6642 7.25 17.25 7.25L2.51577 7.25L8.76728 1.29529C9.06721 1.00961 9.07875 0.534873 8.79306 0.234946C8.50738 -0.0649804 8.03264 -0.0765237 7.73272 0.209164L0.313786 7.27587C0.144866 7.43677 0.0449169 7.642 0.0139358 7.85537C0.00479197 7.90217 7.03505e-07 7.95052 6.99179e-07 8C6.94846e-07 8.04957 0.00480721 8.09802 0.0139854 8.1449C0.0450255 8.35813 0.144961 8.56323 0.313786 8.72404L7.73272 15.7907Z"
        fill="black"
      />
    </svg>
  );
};

export default BackArrow;
