import React, { Component } from "react";
import { Row, Col, Table, Drawer, Button, Popover } from "antd";
import { MenuOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { API } from "../../../API/API";
import UserContext from "../../UserContext/UserContext";
class TenderInvolved extends Component {
  static contextType = UserContext;
  state = {
    data: [],
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    API.get(
      "/getInvitationListInvolved?vendorid=" + this.context.vendorid
    ).then((response) => {
      if (response.status === 200) {
        this.setState({
          data: response.data.retData,
        });
      }
    });
  };
  render() {
    const columns = [
      {
        title: "Урилгын дугаар",
        dataIndex: "invitationcode",
        key: "invitationcode",
        width: "10%",
        align: "left",
      },
      {
        title: "ТШ Код",
        dataIndex: "tendercode",
        key: "tendercode",
        width: "10%",
        align: "left",
      },
      {
        title: "ТШ-ын нэр",
        dataIndex: "tendername",
        key: "tendername",
        width: "20%",
        align: "left",
      },
      {
        title: "Төрөл",
        dataIndex: "tendertypename",
        key: "tendertypename",
        width: "10%",
        align: "left",
      },
      {
        title: "Хүлээн авах хугацаа",
        dataIndex: "acceptdate",
        key: "acceptdate",
        width: "10%",
        align: "left",
      },
      {
        title: "Тайлбар",
        dataIndex: "note",
        key: "note",
        width: "20%",
        align: "left",
      },
      {
        title: "Төлөв",
        dataIndex: "status",
        key: "status",
        width: "8%",
        align: "left",
        render: (text, record) => {
          return (
            <div>
              {record.rejectnote ? "Цуцалсан" : record.status}
            </div>
          );
        },
      },
      {
        title: "Цуцалсан эсэх",
        dataIndex: "rejectnote",
        key: "rejectnote",
        width: "20%",
        align: "left",
      }
    ];
    return (
      <div>
        <div
          className="HeaderLabel">
          Оролцсон тендерийн жагсаалт
        </div>

        <div id="tender-list" style={{ marginRight: 15 }}>
          <Table
            dataSource={this.state.data}
            columns={[
              {
                title: "№",
                dataIndex: "key",
                key: "key",
                width: "3%",
                render: (text, row, index) => (
                  <>{this.state.data.indexOf(row) + 1}</>
                ),
              },
              ...columns,
            ]}
            pagination={true}
            rowKey="tenderid"
          />
        </div>
      </div>
    );
  }
}

export default withRouter(TenderInvolved);
