import React from "react";
const SendIcon = (props) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.721126 0.651592L16.0756 8.21756C16.3233 8.33962 16.4252 8.63937 16.3031 8.88707C16.2544 8.9859 16.1744 9.06588 16.0756 9.11457L0.721442 16.6804C0.473739 16.8024 0.173989 16.7006 0.0519328 16.4529C-0.00143915 16.3446 -0.0138112 16.2206 0.0171017 16.1039L1.9858 8.67022L0.016755 1.22799C-0.0538755 0.961029 0.105278 0.687361 0.372235 0.61673C0.488927 0.585856 0.61285 0.598239 0.721126 0.651592ZM1.26445 2.03413L2.87357 8.11622L2.93555 8.10421L3 8.1001H10C10.2761 8.1001 10.5 8.32396 10.5 8.6001C10.5 8.84556 10.3231 9.04971 10.0899 9.09204L10 9.1001H3C2.9686 9.1001 2.93787 9.0972 2.90807 9.09166L1.26508 15.2977L14.7234 8.66607L1.26445 2.03413Z"
        fill="white"
      />
    </svg>
  );
};

export default SendIcon;
