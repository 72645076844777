import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Table,
  message,
} from "antd";
import { MenuOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "./FilterHeader.css";
import SearchButtonIcon from "../../svg/svgicon/SearchButtonIcon";
const dateFormat = "YYYY.MM.DD";
var moment = require("moment");
class FilterHeader extends Component {
  state = {
    filterDate: "",
    tendername: "",
    invitationcode: "",
    tendertypelist: [{ typeid: 1, typename: "Бараа үйлчилгээ" }],
    tendertypeid: 0,
  };
  onSearch = () => {
    console.log(this.state);
    this.props.onSearch(
      this.state.filterDate,
      this.state.tendername,
      this.state.invitationcode,
      this.state.tendertypeid
    );
  };
  onChangeDate = (value) => {
    this.setState({ filterDate: value ? moment(value, dateFormat) : null });
  };
  onChangeTenderName = (value) => {
    this.setState({ tendername: value });
  };
  onChangeInvitationCode = (value) => {
    this.setState({ invitationcode: value });
  };
  onChangeType = (value) => {
    this.setState({ tendertypeid: value });
  };

  render() {
    return (
      <div id="filterHeader">
        <div className="Container">
          <Row
            style={{ paddingRight: 85, paddingLeft: 85, paddingTop: 15 }}
            gutter={(16, 16)}
          >
            <Col span={3}>
              <div className="FilterLabel">Он</div>
              <DatePicker
                value={
                  this.state.filterDate
                    ? moment(this.state.filterDate, dateFormat)
                    : null
                }
                onChange={this.onChangeDate}
                format={dateFormat}
                style={{ width: "100%", height: 32, borderRadius: 4 }}
                placeholder="--"
              ></DatePicker>
            </Col>
            <Col span={6}>
              <div className="FilterLabel">Тендерийн нэр</div>
              <Input
                style={{ height: 32, borderRadius: 4 }}
                size="large"
                value={this.state.tendername}
                onChange={(e) => this.onChangeTenderName(e.target.value)}
                placeholder="--"
              />
            </Col>
            <Col span={6}>
              <div className="FilterLabel">Урилгын дугаар</div>
              <Input
                style={{ height: 32, borderRadius: 4 }}
                size="large"
                value={this.state.invitationcode}
                onChange={(e) => this.onChangeInvitationCode(e.target.value)}
                placeholder="--"
              />
            </Col>
            <Col span={6}>
              <div className="FilterLabel">Тендерийн төрөл</div>
              <Select
                style={{ width: "100%", height: 32, borderRadius: 8 }}
                showSearch
                allowClear
                optionFilterProp="name"
                onChange={this.onChangeType}
                defaultValue={
                  this.state.tendertypeid == 0 ? null : this.state.tendertypeid
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="--"
              >
                {this.state.tendertypelist &&
                  this.state.tendertypelist?.map((t, i) => (
                    <Select.Option key={i} value={t.typeid}>
                      {t.typename}
                    </Select.Option>
                  ))}
              </Select>
            </Col>
            <Col style={{ marginTop: 38 }} span={3}>
              <Button
                style={{
                  color: "#FFF",
                  backgroundColor: "#292D37",
                  borderRadius: 4,
                  borderColor: "#292D37",
                }}
                icon={<SearchButtonIcon />}
                onClick={this.onSearch}
              >
                Хайх
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(FilterHeader);
