import React, { Component } from "react";
import { Row, Col, List, Card } from "antd";
import { MenuOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "./JoinWorkList.css";
import FilterHeader from "../../Components/MyComponent/FilterHeader";
import { API } from "../../API/API";
class JoinWorkOfVendorList extends Component {
  state = {
    data: [],
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    API.get("/getJoinWorkList").then((response) => {
      if (response.status === 200) {
        this.setState({
          data: response.data.retData,
        });
      }
    });
  };
  render() {
    return (
      <div id="joinwork-list">
        <FilterHeader />
        <div style={{ paddingRight: 100, paddingLeft: 100, marginTop: 15 }}>
          <div className="HeaderLabel">
            Сүүлд зарлагдсан хамтран ажиллах санал
          </div>
          <List
            grid={{ gutter: 16, column: 1 }}
            dataSource={this.state.data}
            renderItem={(item) => (
              <List.Item span={24}>
                <Card.Grid className="card">
                  <Row
                    align="middle"
                    style={{ marginTop: -15, marginBottom: -15 }}
                    gutter={(16, 16)}
                  >
                    <Col span={20}>
                      <Row gutter={[16, 16]}>
                        <Col style={{ marginTop: -10 }} span={24}>
                          <Link
                            className="joinWorkName"
                            to={
                              "/joinworkofvendordtl/" +
                              item.joinworkid +
                              "/" +
                              item.joinworkname
                            }
                          >
                            {item.joinworkname}
                          </Link>
                        </Col>
                        <Col style={{ marginTop: -10 }} span={24}>
                          <div className="cardLabel">
                            Хамтран ажиллах саналын дугаар:{" "}
                            <Link
                              style={{ color: "#0358a7" }}
                              to={
                                "/joinworkofvendordtl/" +
                                item.joinworkid +
                                "/" +
                                item.joinworkname
                              }
                            >
                              {item.joinworkcode}
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="cardDivider" span={4}>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div
                            className="cardLabel"
                            style={{ color: "#605E5C", fontWeight: 400 }}
                          >
                            Зарласан огноо:{" "}
                            <div className="cardLabel">{item.startdate}</div>
                          </div>
                        </Col>
                        <Col style={{ marginTop: -10 }} span={24}>
                          <div
                            className="cardLabel"
                            style={{ color: "#605E5C", fontWeight: 400 }}
                          >
                            Хүлээн авах эцсийн хугацаа:
                          </div>
                        </Col>
                        <Col style={{ marginTop: -10 }} span={24}>
                          <Row justify="start">
                            <Col>
                              <div
                                className="cardLabel"
                                style={{
                                  color:
                                    item.balanceday < 4 ? "#FF0000" : "#212121",
                                }}
                              >
                                {item.enddate}
                              </div>
                            </Col>
                            <Col>
                              <div
                                style={{
                                  marginLeft: 5,
                                  color: "#605E5C",
                                  fontWeight: "normal",
                                }}
                              >
                                /{item.balanceday} өдөр үлдсэн/
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Grid>
              </List.Item>
            )}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(JoinWorkOfVendorList);
