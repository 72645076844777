import React from "react";

const TenderSubMenuIcon = (props) => {
  return (
    <svg
      style={{ marginRight: 7 }}
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.29183 8.58342C3.94665 8.58342 3.66683 8.86325 3.66683 9.20842C3.66683 9.55358 3.94665 9.83342 4.29183 9.83342H9.7085C10.0537 9.83342 10.3335 9.55358 10.3335 9.20842C10.3335 8.86325 10.0537 8.58342 9.7085 8.58342H4.29183ZM4.29183 10.8751C3.94665 10.8751 3.66683 11.1549 3.66683 11.5001C3.66683 11.8452 3.94665 12.1251 4.29183 12.1251H9.7085C10.0537 12.1251 10.3335 11.8452 10.3335 11.5001C10.3335 11.1549 10.0537 10.8751 9.7085 10.8751H4.29183ZM4.29183 13.1667C3.94665 13.1667 3.66683 13.4466 3.66683 13.7917C3.66683 14.1369 3.94665 14.4167 4.29183 14.4167H9.7085C10.0537 14.4167 10.3335 14.1369 10.3335 13.7917C10.3335 13.4466 10.0537 13.1667 9.7085 13.1667H4.29183ZM8.321 1.15508L13.1785 6.01175C13.491 6.32425 13.6668 6.74841 13.6668 7.19008V15.6667C13.6668 16.5867 12.9202 17.3334 12.0002 17.3334H2.00016C1.08016 17.3334 0.333496 16.5867 0.333496 15.6667V2.33341C0.333496 1.41341 1.08016 0.666748 2.00016 0.666748H7.1435C7.16675 0.666748 7.18941 0.66984 7.212 0.672923C7.22833 0.675148 7.24458 0.677365 7.261 0.678415C7.44016 0.690915 7.61683 0.725081 7.781 0.793415C7.82883 0.813815 7.87416 0.840056 7.91933 0.86619C7.93325 0.874223 7.94708 0.88224 7.961 0.890081C7.97391 0.89714 7.98725 0.903631 8.00058 0.91014C8.02358 0.921315 8.04658 0.932531 8.06766 0.946748C8.13266 0.990915 8.191 1.04341 8.25016 1.09675C8.25975 1.10525 8.27 1.11314 8.28041 1.12108C8.29441 1.13181 8.3085 1.14262 8.321 1.15508ZM12.0002 16.0834C12.2302 16.0834 12.4168 15.8959 12.4168 15.6667V7.33342H8.66683C7.74683 7.33342 7.00016 6.58675 7.00016 5.66675V1.91675H2.00016C1.77016 1.91675 1.5835 2.10425 1.5835 2.33341V15.6667C1.5835 15.8959 1.77016 16.0834 2.00016 16.0834H12.0002ZM11.4818 6.08342L8.25016 2.85091V5.66675C8.25016 5.89592 8.43683 6.08342 8.66683 6.08342H11.4818Z"
        fill="#605E5C"
      />
    </svg>
  );
};

export default TenderSubMenuIcon;
