import React from "react";
const SearchButton = (props) => {
  return (
    <svg
      style={{ marginRight: 10, paddingTop: 3 }}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.25 8C18.25 12.0041 15.0041 15.25 11 15.25C9.26813 15.25 7.67808 14.6427 6.43118 13.6295L1.53033 18.5303C1.23744 18.8232 0.762561 18.8232 0.469669 18.5303C0.203403 18.2641 0.179195 17.8474 0.39705 17.5538L0.469669 17.4697L5.37045 12.5688C4.35725 11.3219 3.75 9.73187 3.75 8C3.75 3.99593 6.99594 0.75 11 0.75C15.0041 0.75 18.25 3.99594 18.25 8ZM16.75 8C16.75 4.82436 14.1756 2.25 11 2.25C7.82436 2.25 5.25 4.82436 5.25 8C5.25 11.1756 7.82436 13.75 11 13.75C14.1756 13.75 16.75 11.1756 16.75 8Z"
        fill="white"
      />
    </svg>
  );
};

export default SearchButton;
