import React from "react";
import { Row, Col, Button } from "antd";
import "./MenuVertical.css";
const PopConfirm = (props) => {
  return (
    <div
      style={{
        paddingLeft: 20,
        paddingRight: 20,
        width: 350,
      }}
    >
      <div
        style={{
          marginBottom: 5,
          fontSize: 12,
          fontWeight: 500,
          color: "#201F1E",
        }}
      >
        {props.question}
      </div>
      <Row justify="end" align="middle">
        <Col>
          <Button
            type="primary"
            style={{
              backgroundColor: "#0078D4",
              borderRadius: 4,
              marginTop: 20,
            }}
            onClick={props.onClickYes}
          >
            Тийм
          </Button>
        </Col>
        <Col>
          <Button
            type="default"
            style={{
              fontWeight: 600,
              fontSize: 14,
              color: "#212121",
              borderRadius: 2,
              marginTop: 20,
              marginLeft: 10,
            }}
            onClick={props.onClickNo}
          >
            Үгүй
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default PopConfirm;
