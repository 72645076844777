import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Table,
  message,
  TreeSelect,
} from "antd";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import "./CompanyInfo.css";
import UserContext from "../UserContext/UserContext";
import { API } from "../../API/API";
import axios from "axios";
import { baseConfig } from "../../Config/Config";

const dateFormat = "YYYY.MM.DD";
var moment = require("moment");
class CompanyInfo extends Component {
  static contextType = UserContext;
  formRef = React.createRef();
  state = {
    vendorid: this.context.vendorid,
    username: this.context.username,
    vendorname: "",
    registernumber: "",
    isvatpayer: 1,
    vendortypeid: 1,
    countryname: "",
    countries: [],
    establisheddate: "",
    activity: "",
    activitylist: [],
    vendorstatusid: 0,
    address: "",
    vendorphone: "",
    vendoremail: "",
    empname: "",
    empphone: "",
    empemail: "",
    bankname: "",
    bankaccountname: "",
    bankaccountnumber: "",
    headcompany: "",
    shareholder: "",
    website: "",
    dkey: 1,
    vendorcategory_ids: [],
    vendorcategoryoptions: [],

  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    axios.get("/api/v0.1/countries", {
      baseURL: "https://countriesnow.space",
    }).then((response) => {
      if (response.status === 200) {
        this.setState({
          countries: response.data.data,
        });
      }
    });
    API.post('/getVendorCategory', { url: baseConfig.makurl }).then((vendorcategoryoptions) => {
      if (vendorcategoryoptions.status === 200) {
        let tmp = [];
        vendorcategoryoptions.data.retData.map((category, index) => {
          if (category.parent_id === null) {
            let formated_category = {
              title: category.name,
              value: category.id + '',
              children: []
            }
            vendorcategoryoptions.data.retData.map((sub_category, sub_index) => {
              if (sub_category.parent_id === category.id) {
                formated_category.children.push({ title: sub_category.name, value: sub_category.id })
              }
            })
            tmp.push(formated_category);
          }
        })
        this.setState({
          vendorcategoryoptions: tmp
        });

      }
    });
    API.get("/getVendorCategory?vendorid=" + this.state.vendorid).then((response) => {
      if (response.status === 200 && response.data.retData) {
        let categids = [];
        response.data.retData.map((element) => {
          categids.push(element.erpcategoryid);
        });
        this.setState({
          vendorcategory_ids: categids
        });
      }
    })
    API.get("/getVendorActivityList").then((response) => {
      if (response.status === 200) {
        this.setState({
          activitylist: response.data.retData,
        });
      }
    });

    API.get("/getVendorInfo?vendorid=" + this.state.vendorid).then(
      (response) => {
        if (response.status === 200 && response.data.retData) {
          this.setState((prevState) => ({
            vendorname: response.data.retData[0].vendorname,
            registernumber: response.data.retData[0].registernumber,
            isvatpayer: response.data.retData[0].isvatpayer,
            vendortypeid: response.data.retData[0].vendortypeid,
            countryname: response.data.retData[0].countryname,
            establisheddate: response.data.retData[0].establisheddate,
            activity: response.data.retData[0].activity,
            vendorstatusid: response.data.retData[0].vendorstatusid,
            address: response.data.retData[0].address,
            vendorphone: response.data.retData[0].vendorphone,
            vendoremail: response.data.retData[0].vendoremail,
            empname: response.data.retData[0].empname,
            empphone: response.data.retData[0].empphone,
            empemail: response.data.retData[0].empemail,
            bankname: response.data.retData[0].bankname,
            bankaccountname: response.data.retData[0].bankaccountname,
            bankaccountnumber: response.data.retData[0].bankaccountnumber,
            headcompany: response.data.retData[0].headcompany,
            shareholder: response.data.retData[0].shareholder,
            website: response.data.retData[0].website,
            dkey: prevState.dkey + 1,
          }));

        }
      }
    );

  };
  onSave = () => {
    if (
      this.state.countryname &&
      this.state.vendorname
    ) {
      if (this.state.vendortypeid != 2) {
        if (
          this.state.address &&
          this.state.vendorphone &&
          this.state.vendoremail &&
          this.state.empname &&
          this.state.empphone &&
          this.state.empemail
        ) {
          this.saveRegister();
        }
        else {
          message.warning("Заавал бөглөх талбарууд дутуу байна.");
        }
      }
      else {
        this.saveRegister();
      }
    } else {
      message.warning("Заавал бөглөх талбарууд дутуу байна.");
    }
  };

  saveRegister = () => {
    let body = {
      vendorid: this.state.vendorid,
      vendortypeid: this.state.vendortypeid,
      countryname: this.state.countryname,
      vendorname: this.state.vendorname,
      registernumber: this.state.registernumber,
      isvatpayer: this.state.isvatpayer,
      establisheddate: this.state.establisheddate,
      activity: this.state.activity,
      vendorstatusid: this.state.vendorstatusid,
      address: this.state.address,
      vendorphone: this.state.vendorphone,
      vendoremail: this.state.vendoremail,
      bankname: this.state.bankname,
      bankaccountname: this.state.bankaccountname,
      bankaccountnumber: this.state.bankaccountnumber,
      username: this.state.username,
      empname: this.state.empname,
      empphone: this.state.empphone,
      empemail: this.state.empemail,
      password: this.state.password,
      headcompany: this.state.headcompany,
      shareholder: this.state.shareholder,
      website: this.state.website,
      vendorcategory_ids: this.state.vendorcategory_ids + ""
    };
    API.post("/saveVendor", body).then((response) => {
      if (response.status === 200 && response.data.retType == 0) {
        message.success("Амжилттай хадгаллаа.");
      }
    });
  }

  onChangeEstablishedDate = (value) => {
    this.setState({ establisheddate: moment(value, dateFormat) });
  };

  onChangeCountry = (value) => {
    this.setState({ countryname: value });
  };

  onChangeType = (value) => {
    this.setState({ vendortypeid: value });
  };

  onChangeVendorName = (value) => {
    this.setState({ vendorname: value });
  };
  onchangeActivity = (value) => {
    this.setState({ vendorcategory_ids: value })
  }
  onChangeRegno = (value) => {
    this.setState({ registernumber: value });
  };

  onChangeIsVat = (value) => {
    this.setState({ isvatpayer: value });
  };

  onChangeActivity = (value) => {
    this.setState({ activity: value });
  };
  onChangeVendorIDs = (value) => {
    this.setState({ vendorcategory_ids: value });
  }
  onChangeVendorStatusID = (value) => {
    this.setState({ vendorstatusid: value });
  };

  onChangeAddress = (value) => {
    this.setState({ address: value });
  };

  onChangeVendorPhone = (value) => {
    this.setState({ vendorphone: value });
  };

  onChangeVendorEmail = (value) => {
    this.setState({ vendoremail: value });
  };

  onChangeEmpName = (value) => {
    this.setState({ empname: value });
  };

  onChangeEmpPhone = (value) => {
    this.setState({ empphone: value });
  };

  onChangeEmpMail = (value) => {
    this.setState({ empemail: value });
  };

  onChangeBankName = (value) => {
    this.setState({ bankname: value });
  };

  onChangeBankAccountName = (value) => {
    this.setState({ bankaccountname: value });
  };

  onChangeBankAccountNumber = (value) => {
    this.setState({ bankaccountnumber: value });
  };

  onChangeWebSite = (e) => {
    this.setState({ website: e });
  };

  onChangeHeadCompany = (e) => {
    this.setState({ headcompany: e });
  };

  onChangeShareHolder = (e) => {
    this.setState({ shareholder: e });
  };
  render() {
    return (
      <div id="companyInfo" style={{ padding: 15 }} key={this.state.dkey}>
        <Form
          ref={this.formRef}
          onFinish={this.onSubmit}
          layout="vertical"
          initialValues={{}}
        >
          <div
            className="HeaderLabel">
            Компаний талаархи мэдээлэл
          </div>
          <Row justify="end" style={{ marginTop: -70 }}>
            <Col>
              <Form.Item label=" ">
                <Button
                  type="primary"
                  style={{ backgroundColor: "#0358A7", borderRadius: 2 }}
                  htmlType="submit"
                  onClick={this.onSave}
                >
                  Хадгалах
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: -15 }} gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Нэр"
                name="vendorname"
                rules={[
                  {
                    required: true,
                    message: "Нэр оруулна уу.",
                  },
                ]}
                initialValue={this.state.vendorname}
              >
                <Input
                  style={{ height: 30 }}
                  size="large"
                  defaultValue={this.state.vendorname}
                  onChange={(e) => this.onChangeVendorName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Регистрийн дугаар"
                name="registernumber"
                initialValue={this.state.registernumber}
              >
                <Input
                  style={{ height: 30 }}
                  disiabled
                  size="large"
                  defaultValue={this.state.registernumber}
                  onChange={(e) => this.onChangeRegno(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="НӨАТ төлөгч эсэх"
                name="isvatpayer"
                rules={[
                  {
                    required: true,
                    message: "Төрөл сонгоно уу.",
                  },
                ]}
                initialValue={this.state.isvatpayer}
              >
                <Select
                  style={{ width: "100%", height: 30 }}
                  showSearch
                  allowClear
                  optionFilterProp="name"
                  onChange={() => this.onChangeIsVat}
                  defaultValue={this.state.isvatpayer}
                >
                  <Select.Option key={1} value={1}>
                    Тийм
                  </Select.Option>
                  <Select.Option key={2} value={0}>
                    Үгүй
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: -15 }} gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Төрөл"
                name="vendortypeid"
                rules={[
                  {
                    required: true,
                    message: "Төрөл сонгоно уу.",
                  },
                ]}
                initialValue={this.state.vendortypeid}
              >
                <Select
                  style={{ width: "100%", height: 30 }}
                  showSearch
                  allowClear
                  optionFilterProp="name"
                  onChange={this.onChangeType}
                  defaultValue={this.state.vendortypeid}
                >
                  <Select.Option key={1} value={1}>
                    ААН
                  </Select.Option>
                  <Select.Option key={2} value={2}>
                    Хувь хүн
                  </Select.Option>
                  <Select.Option key={3} value={3}>
                    Гадаад
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Улс"
                name="countryname"
                rules={[
                  {
                    required: true,
                    message: "Улс оруулна уу.",
                  },
                ]}
                initialValue={this.state.countryname}
              >
                <Select
                  style={{ width: "100%", height: 30 }}
                  showSearch
                  allowClear
                  optionFilterProp="name"
                  onChange={this.onChangeCountry}
                  defaultValue={this.state.countryname}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.countries &&
                    this.state.countries?.map((t, i) => (
                      <Select.Option key={i} value={t.country}>
                        {t.country}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Компани байгуулагдсан огноо"
                name="establisheddate"
                initialValue={
                  this.state.establisheddate
                    ? moment(this.state.establisheddate, dateFormat)
                    : null
                }
              >
                <DatePicker
                  defaultValue={
                    this.state.establisheddate
                      ? moment(this.state.establisheddate, dateFormat)
                      : null
                  }
                  onChange={this.onChangeEstablishedDate}
                  format={dateFormat}
                  style={{ width: "100%", height: 30 }}
                  placeholder=""
                ></DatePicker>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: -15 }} gutter={[16, 16]}>
             {/* <Col span={16}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Үйл ажиллагааны чиглэл"
                name="activity"
                rules={[
                  {
                    required: true,
                    message: "Чиглэл оруулна уу.",
                  },
                ]}
                initialValue={this.state.activity}
              >
                <Select
                  style={{ width: "100%", height: 30 }}
                  showSearch
                  allowClear
                  optionFilterProp="name"
                  onChange={this.onChangeActivity}
                  defaultValue={this.state.activity}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.activitylist &&
                    this.state.activitylist?.map((t, i) => (
                      <Select.Option key={i} value={t.activity}>
                        {t.activity}
                      </Select.Option>
                    ))}
                </Select>

              </Form.Item>
             </Col> */}
            <Col span={16}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Үйл ажиллагааны чиглэл"
                name="vendorcategory_ids"
                rules={[
                  {
                    required: true,
                    message: "Чиглэл оруулна уу.",
                  },
                ]}
                initialValue={this.state.activity}
              >
                <TreeSelect
                  style={{ width: "100%", height: 30 }}


                  onChange={this.onChangeVendorIDs}
                  multiple
                  defaultValue={this.state.vendorcategory_ids}
                  value={this.state.vendorcategory_ids}
                  treeDefaultExpandAll
                  treeData={this.state.vendorcategoryoptions}
                />

                <div style={{ display: 'none' }}>{this.state.vendorcategory_ids + ""}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Компанийн статус"
                name="vendorstatusid"
                rules={[
                  {
                    required: true,
                    message: "Статус оруулна уу.",
                  },
                ]}
                initialValue={this.state.vendorstatusid}
              >
                <Select
                  style={{ width: "100%", height: 30 }}
                  showSearch
                  allowClear
                  optionFilterProp="name"
                  onChange={this.onChangeVendorStatusID}
                  defaultValue={this.state.vendorstatusid}
                >
                  <Select.Option key={1} value={1}>
                    Импортлогч
                  </Select.Option>
                  <Select.Option key={2} value={0}>
                    Дистрибьютер
                  </Select.Option>
                  <Select.Option key={3} value={2}>
                    Үйлдвэрлэгч
                  </Select.Option>
                  <Select.Option key={4} value={3}>
                    Төлөөлөгч
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: -15 }} gutter={[16, 16]}>
            <Col span={16}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Компанийн хаяг"
                name="address"
                rules={[
                  {
                    required: this.state.vendortypeid != 2 ? true : false,
                    message: "Хаяг оруулна уу.",
                  },
                ]}
                initialValue={this.state.address}
              >
                <Input
                  style={{ height: 30 }}
                  disiabled
                  size="large"
                  defaultValue={this.state.address}
                  onChange={(e) => this.onChangeAddress(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Байгууллагын утас"
                name="vendorphone"
                rules={[
                  {
                    required: this.state.vendortypeid != 2 ? true : false,
                    message: "Утас оруулна уу.",
                  },
                ]}
                initialValue={this.state.vendorphone}
              >
                <Input
                  style={{ height: 30 }}
                  disiabled
                  size="large"
                  defaultValue={this.state.vendorphone}
                  onChange={(e) => this.onChangeVendorPhone(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Толгой компани"
                name="headcompany"
                initialValue={this.state.headcompany}
              >
                <Input
                  style={{ height: 30 }}
                  disiabled
                  size="large"
                  defaultValue={this.state.headcompany}
                  onChange={(e) => this.onChangeHeadCompany(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Хувьцаа эзэмшигч"
                name="shareholder"
                initialValue={this.state.shareholder}
              >
                <Input
                  style={{ height: 30 }}
                  disiabled
                  size="large"
                  defaultValue={this.state.shareholder}
                  onChange={(e) => this.onChangeShareHolder(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Вэб сайт"
                name="website"
                initialValue={this.state.website}
              >
                <Input
                  style={{ height: 30 }}
                  disiabled
                  size="large"
                  defaultValue={this.state.website}
                  onChange={(e) => this.onChangeWebSite(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: -20 }} gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Байгууллагын и-мэйл хаяг"
                name="vendoremail"
                rules={[
                  {
                    required: this.state.vendortypeid != 2 ? true : false,
                    message: "И-мэйл оруулна уу.",
                  },
                ]}
                initialValue={this.state.vendoremail}
              >
                <Input
                  style={{ height: 30 }}
                  disiabled
                  size="large"
                  defaultValue={this.state.vendoremail}
                  onChange={(e) => this.onChangeVendorEmail(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <div
            className="HeaderLabel"
            style={{ marginBottom: 15, fontWeight: 400 }}
          >
            Холбоо барих ажилтны мэдээлэл
          </div>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Нэр"
                name="empname"
                rules={[
                  {
                    required: this.state.vendortypeid != 2 ? true : false,
                    message: "Нэр оруулна уу.",
                  },
                ]}
                initialValue={this.state.empname}
              >
                <Input
                  style={{ height: 30 }}
                  size="large"
                  defaultValue={this.state.empname}
                  onChange={(e) => this.onChangeEmpName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Утас"
                name="empphone"
                rules={[
                  {
                    required: this.state.vendortypeid != 2 ? true : false,
                    message: "Утас оруулна уу.",
                  },
                ]}
                initialValue={this.state.empphone}
              >
                <Input
                  style={{ height: 30 }}
                  disiabled
                  size="large"
                  defaultValue={this.state.empphone}
                  onChange={(e) => this.onChangeEmpPhone(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="И-мэйл хаяг"
                name="empemail"
                rules={[
                  {
                    required: this.state.vendortypeid != 2 ? true : false,
                    message: "И-мэйл оруулна уу.",
                  },
                ]}
                initialValue={this.state.empemail}
              >
                <Input
                  style={{ height: 30 }}
                  disiabled
                  size="large"
                  defaultValue={this.state.empemail}
                  onChange={(e) => this.onChangeEmpMail(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <div
            className="HeaderLabel"
            style={{ marginBottom: 15, fontWeight: 400 }}
          >
            Банкны мэдээлэл
          </div>
          <Row style={{ marginBottom: -15 }} gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Банк"
                name="bankname"
                initialValue={this.state.bankname}
              >
                <Input
                  style={{ height: 30 }}
                  size="large"
                  defaultValue={this.state.bankname}
                  onChange={(e) => this.onChangeBankName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Дансны нэр"
                name="bankaccountname"
                initialValue={this.state.bankaccountname}
              >
                <Input
                  style={{ height: 30 }}
                  disiabled
                  size="large"
                  defaultValue={this.state.bankaccountname}
                  onChange={(e) => this.onChangeBankAccountName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label="Дансны дугаар"
                name="bankaccountnumber"
                initialValue={this.state.bankaccountnumber}
              >
                <Input
                  style={{ height: 30 }}
                  disiabled
                  size="large"
                  defaultValue={this.state.bankaccountnumber}
                  onChange={(e) =>
                    this.onChangeBankAccountNumber(e.target.value)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(CompanyInfo);
