import React, { Component } from "react";
import { Row, Col, Button, Popover, Typography, Space } from "antd";
import { withRouter } from "react-router-dom";
import "./JoinWork.css";
import PopConfirm from "../../Components/MyComponent/PopConfirm";
import { API } from "../../API/API";
import UserContext from "../UserContext/UserContext";
const { Text } = Typography;
const Str = require('@supercharge/strings');
class JoinWorkDtl extends Component {
  static contextType = UserContext;
  state = {
    joinworkid: this.props.match.params.joinworkid,
    joinworkcode: this.props.match.params.joinworkcode,
    joinworkname: this.props.match.params.joinworkname,
    departmentname: this.props.match.params.departmentname,
    tendertypename: this.props.match.params.tendertypename,
    status: "Хүлээн авч байгаа",
    startdate: "",
    enddate: "",
    note: "",
    text: [],
    popConfirmVisible: false,
    dkey: 1,
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    API.get("/getJoinWorkById?joinworkid=" + this.state.joinworkid).then(
      (response) => {
        if (response.status === 200 && response.data.retType === 0) {
          if (response.data.retData.length > 0) {
            this.setState((prevState) => ({
              joinworkcode: response.data.retData[0].joinworkcode,
              joinworkname: response.data.retData[0].joinworkname,
              departmentname: response.data.retData[0].departmentname,
              tendertypename: response.data.retData[0].tendertypename,
              startdate: response.data.retData[0].startdate,
              enddate: response.data.retData[0].enddate,
              note: response.data.retData[0].note,
              text: Str(response.data.retData[0].note).lines(),
              dkey: prevState.dkey + 1,
            }));
          }
        }
      }
    );
  };
  popConfirm = () => {
    return (
      <PopConfirm
        question="Та хамтран ажиллах санал итгэлтэй байнуу?"
        onClickYes={this.onCallRequest}
        onClickNo={() => this.onChangePopConfirmVisible(false)}
      />
    );
  };
  onChangePopConfirmVisible = (visible) => {
    this.setState({ popConfirmVisible: visible });
  };
  onCallRequest = () => {
    this.setState({ popConfirmVisible: false });
    this.props.history.push(
      "/joinworkrequest/" +
        this.state.joinworkid +
        "/" +
        this.state.joinworkname +
        "/" +
        this.state.joinworkcode +
        "/" +
        this.state.tendertypename +
        "/" +
        this.state.enddate
    );
  };
  render() {
    return (
      <div id="tenderPossible" key={this.state.dkey}>
        <div style={{ padding: 20 }}>
          <Row gutter={(16, 16)}>
            <Col span={12}>
              <Row justify="start">
                <div className="HeaderLabel" style={{ marginBottom: 15 }}>
                  {this.state.joinworkname}
                </div>
              </Row>
            </Col>
            <Col span={12}>
              <Row justify="end">
                <Popover
                  placement="bottomRight"
                  title="Санамж"
                  content={this.popConfirm}
                  trigger="click"
                  visible={this.state.popConfirmVisible}
                >
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#0358A7",
                      borderRadius: 4,
                      marginTop: 20,
                    }}
                    htmlType="submit"
                    onClick={() => this.onChangePopConfirmVisible(true)}
                  >
                    Санал илгээх
                  </Button>
                </Popover>
              </Row>
            </Col>
          </Row>
          <div style={{ padding: 15 }}>
            <Row gutter={(16, 16)}>
              <Col className="headerTable-leftSide" span={12}>
                <div className="headerTable-leftSide-Text">Захиалагч:</div>
              </Col>
              <Col className="headerTable-rightSide" span={12}>
                <div className="headerTable-rightSide-Text">
                  Монголын алт ХХК - Хангамж техник үйлчилгэээний газар
                </div>
              </Col>
              <Col className="headerTable-leftSide" span={12}>
                <div className="headerTable-leftSide-Text">
                  Хамтран ажиллах саналын төрөл:
                </div>
              </Col>
              <Col className="headerTable-rightSide" span={12}>
                <div className="headerTable-rightSide-Text">
                  {this.state.tendertypename}
                </div>
              </Col>
              <Col className="headerTable-leftSide" span={12}>
                <div className="headerTable-leftSide-Text">
                  Хамтран ажиллах саналын дугаар:
                </div>
              </Col>
              <Col className="headerTable-rightSide" span={12}>
                <div className="headerTable-rightSide-Text">
                  {this.state.joinworkcode}
                </div>
              </Col>
              <Col className="headerTable-leftSide" span={12}>
                <div className="headerTable-leftSide-Text">
                  Хамтран ажиллах саналын төлөв:
                </div>
              </Col>
              <Col className="headerTable-rightSide" span={12}>
                <div className="headerTable-rightSide-Text">
                  {this.state.status}
                </div>
              </Col>
            </Row>
          </div>
          <div className="content-Text" style={{ marginTop: 5 }}>
            <Space direction="vertical">
              {this.state.text.map(value => 
                <Text>{value}</Text>
              )
              }
            </Space>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(JoinWorkDtl);
