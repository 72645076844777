import React, { Component } from "react";
import { Row, Col, Table } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "./JoinWork.css";
import { API } from "../../API/API";
class JoinWorkList extends Component {
  state = {
    data: [],
    showNewInputForm: false,
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    API.get("/getJoinWorkListForVendor?vendorid=" + localStorage.getItem("vendorid")).then((response) => {
      if (response.status === 200) {
        this.setState({
          data: response.data.retData,
        });
      }
    });
  };
  showNewInputFormHandler = (show) => {
    this.setState({
      showNewInputForm: show,
    });
  };
  render() {
    const columns = [
      {
        title: "Код",
        dataIndex: "joinworkcode",
        key: "joinworkcode",
        width: "7%",
        align: "left",
        render: (text, record) => {
          return (
            <Link
              style={{ fontWeight: "500", color: "#0358A7" }}
              key={record.joinworkid}
              to={`/joinworkdtl/${record.joinworkid}/${record.joinworkname}/${record.joinworkcode}/${record.tendertypename}`}
            >
              {record.joinworkcode}
            </Link>
          );
        },
      },
      {
        title: "Хамтран ажиллах санлын нэр",
        dataIndex: "joinworkname",
        key: "joinworkname",
        width: "25%",
        align: "left",
      },
      {
        title: "Төрөл",
        dataIndex: "tendertypename",
        key: "tendertypename",
        width: "10%",
        align: "left",
      },
      {
        title: "Хугацаа",
        dataIndex: "dateinterval",
        key: "dateinterval",
        width: "20%",
        align: "left",
        render: (text, record) => {
          return (
            <div>
              {record.startdate} - {record.enddate}
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <Row style={{ height: 56 }} justify="start" align="middle">
          <Col>
            <div
              className="HeaderLabel">
              Хамтран ажиллах саналын жагсаалт
            </div>
          </Col>
        </Row>

        <div id="joinwork-list" style={{ marginRight: 15 }}>
          <Table
            dataSource={this.state.data}
            columns={[
              {
                title: "№",
                dataIndex: "key",
                key: "key",
                width: "3%",
                render: (text, row, index) => (
                  <>{this.state.data.indexOf(row) + 1}</>
                ),
              },
              ...columns,
            ]}
            pagination={true}
            rowKey="joinid"
          />
        </div>
      </div>
    );
  }
}

export default withRouter(JoinWorkList);
