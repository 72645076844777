import React, { Component } from "react";
import { Row, Col, Menu, Dropdown, Avatar } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link, withRouter } from "react-router-dom";
import UserContext from "../../Page/UserContext/UserContext";
import "./HeaderNew.css";
import Logo from "../../svg/svgicon/LogoSmall";
import HeaderMenu from "../../svg/svgicon/HeaderMenu";
import NotificationIcon from "../../svg/svgicon/NotificationIcon";
class Header2 extends Component {
  state = {
    menu: [],
  };

  onMenuChange = (e) => {
    if (e.key) {
      this.setState({
        sMenu: parseInt(e.key),
      });
    }
  };

  onLogin = () => {
    this.props.history.push("/login");
  };

  render() {
    let userInfo = (
      <Menu>
        <Menu.Item
          key={0}
          icon={<LogoutOutlined />}
          style={{ width: "100px", textAlign: "center" }}
          onClick={this.props.logout}
        >
          <a>Гарах</a>
        </Menu.Item>
      </Menu>
    );

    return (
      <div>
        <Row
          style={{
            backgroundColor: "#292D37",
            height: "48px",
            position: "fixed",
            zIndex: "2",
            width: "100%",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
          align="middle"
        >
          <Col span={12}>
            <Row align="middle" gutter={[16, 16]}>
              <Col>
                <HeaderMenu />
              </Col>
              <Col style={{ marginLeft: 10 }}>
                <Link to="/">
                  <div className="loggedUsername">MAK</div>
                </Link>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row justify="end" align="middle" gutter={[16, 16]}>
              {/* <Col>
                <NotificationIcon />
              </Col> */}
              <Col>
                <div className="loggedUsername">{this.props.username}</div>
              </Col>
              <Col>
                <Dropdown
                  placement="bottomRight"
                  overlay={userInfo}
                  trigger={["click"]}
                >
                  <Avatar
                    style={{ cursor: "pointer" }}
                    size={32}
                    icon={<UserOutlined />}
                  ></Avatar>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
Header2.contextType = UserContext;
export default withRouter(Header2);
