import React, { Component } from "react";
import { Row, Col, Table, Button, Upload, message, Drawer } from "antd";
import { withRouter, Link } from "react-router-dom";
import FileDownload from "js-file-download";
import { InfoCircleTwoTone } from "@ant-design/icons";
import "./Document.css";
import PlusIcon from "../../../../svg/svgicon/PlusIcon";
import ButtonLinkIcon from "../../../../Components/MyComponent/ButtonLinkIcon";
import UserContext from "../../../UserContext/UserContext";
import DocumentAdd from "./DocumentAdd";
import { API } from "../../../../API/API";
class DocumentList extends Component {
  static contextType = UserContext;
  state = {
    invitationid: this.props.invitationid,
    showNewInputForm: false,
    tenderid: this.props.tenderid,
    selectedDocId: 0,
    data: [],
  };
  showNewInputFormHandler = (show, docid) => {
    this.setState({
      showNewInputForm: show,
      selectedDocId: docid,
    });
    if (show === false) {
      this.getData();
    }
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    API.get(
      "/getTenderDocJoinList?vendorid=" +
        this.context.vendorid +
        "&invitationid=" +
        this.state.invitationid
    ).then((response) => {
      if (response.status === 200) {
        this.setState({
          data: response.data.retData,
        });
      }
    });
  };
  onDownLoadFile = (sourceid, sourcetype, filename) => {
    API.get("/download?sourceid=" + sourceid + "&sourcetype=" + sourcetype, {
      responseType: "blob",
    }).then((response) => {
      if (response.status === 200) {
        FileDownload(response.data, filename);
      }
    });
  };
  onDeleteFile = (sourceid, sourcetype) => {
    API.get("/deleteFile?sourceid=" + sourceid + "&sourcetype=" + sourcetype, {
      responseType: "blob",
    }).then((response) => {
      if (response.status === 200) {
        this.getData();
      }
    });
  };
  uploadFile = (info) => {
    if (info.file.status != "uploading") {
      let body = {
        invitationid: this.state.invitationid,
        documentname: info.file.name,
        vendorid: this.context.vendorid,
      };
      API.post("/saveTenderJoinDoc", body).then((response) => {
        if (response.status === 200 && response.data.retType === 0) {
          let docid = response.data.retData;
          const formData = new FormData();
          formData.append(info.file.name, info.file.originFileObj);
          API.post(
            "/saveTenderDocFile?sourcetype=TenderJoinDoc&docid=" + docid,
            formData
          ).then((response) => {
            if (response.status === 200 && response.data.retType === 0) {
              message.success("Амжилттай хадгаллаа.");
              this.getData();
            }
          });
        }
      });
    }
  };
  showNewInputFormHandler = (show, docid) => {
    this.setState({
      showNewInputForm: show,
      selectedDocId: docid,
    });
    if (show === false) {
      this.getData();
    }
  };
  render() {
    const columns = [
      {
        title: "Нэр",
        dataIndex: "filename",
        key: "filename",
        width: "20%",
        align: "left",
        render: (text, record) => {
          return (
            <Button
              type="link"
              style={{ fontWeight: "500", color: "#0358A7" }}
              onClick={() =>
                this.onDownLoadFile(
                  record.sourceid,
                  record.sourcetype,
                  record.filename
                )
              }
            >
              {record.filename}
            </Button>
          );
        },
      },
      {
        title: "Төрөл",
        dataIndex: "requirevalue",
        key: "requirevalue",
        width: "20%",
        align: "left",
      },
      {
        title: "Багц",
        dataIndex: "batchname",
        key: "batchname",
        width: "15%",
        align: "left",
      },
      {
        title: this.props.isEdit === true ? "Үйлдэл" : null,
        dataIndex: "action",
        key: "action",
        width: "20%",
        align: "left",
        render: (text, record) => {
          return this.props.isEdit === true ? (
            <Row justify="start">
              <Button
                type="link"
                style={{ fontWeight: "500", color: "#0358A7" }}
                onClick={() =>
                  this.onDownLoadFile(
                    record.sourceid,
                    record.sourcetype,
                    record.filename
                  )
                }
              >
                Татах
              </Button>
              <div
                style={{
                  marginTop: 5,
                  fontWeight: "500",
                  color: "#0358A7",
                }}
              >
                |
              </div>
              <Button
                type="link"
                style={{ fontWeight: "500", color: "#0358A7" }}
                onClick={() =>
                  this.onDeleteFile(record.sourceid, record.sourcetype)
                }
              >
                Устгах
              </Button>
            </Row>
          ) : null;
        },
      },
    ];
    return (
      <div>
        <Row style={{ height: 56 }} justify="start" align="middle">
          <Col>
            <div className="HeaderLabel">1. Баримт, бичиг</div>
          </Col>
          <Col>
            <div className="divider"></div>
          </Col>
          {this.props.isEdit === true ? (
            <Col>
              {/* <Upload
                accept="*"
                openFileDialogOnClick
                onChange={this.uploadFile}
                showUploadList={false}
                multiple={false}
              >
                <ButtonLinkIcon icon={<PlusIcon />} name="Баримт бичиг нэмэх" />
              </Upload> */}
              <ButtonLinkIcon
                onClick={() => this.showNewInputFormHandler(true, 0)}
                icon={<PlusIcon />}
                name="Баримт нэмэх"
              />
              <Drawer
                title="Бичиг,баримт нэмэх"
                width="500px"
                placement="right"
                visible={this.state.showNewInputForm}
                onClose={() => this.showNewInputFormHandler(false, 0)}
                destroyOnClose={true}
                style
              >
                <DocumentAdd
                  invitationid={this.state.invitationid}
                  docid={this.state.selectedDocId}
                  showForm={this.showNewInputFormHandler}
                  tenderid={this.state.tenderid}
                  isEdit={this.state.isEdit}
                ></DocumentAdd>
              </Drawer>
            </Col>
          ) : null}
          {this.props.isEdit === true ? (
            <Col>
              <div style={{fontSize: 15, color: "orange", marginLeft: 40}}>
                <InfoCircleTwoTone style={{marginRight: 5}} /> Бүрдүүлэх материалаа жагсаалтын дагуу тус тусад нь оруулахыг анхаарна уу
              </div>
            </Col>
          ) : null }
        </Row>

        <div id="member-list">
          <Table
            dataSource={this.state.data}
            columns={[
              {
                title: "№",
                dataIndex: "key",
                key: "key",
                width: "3%",
                render: (text, row, index) => (
                  <>{this.state.data.indexOf(row) + 1}</>
                ),
              },
              ...columns,
            ]}
            pagination={false}
            rowKey="documentid"
            scroll={{ y: 340 }}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(DocumentList);
