import React from "react";
const NotificationIcon = (props) => {
  return (
    <svg
      style={{ margin: 10, marginTop: 15 }}
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99999 0.996231C13.0499 0.996231 16.3567 4.19097 16.4958 8.24528L16.5 8.49623V12.5932L17.88 15.7492C17.949 15.9071 17.9847 16.0776 17.9847 16.25C17.9847 16.9404 17.425 17.5 16.7347 17.5L12 17.5015C12 19.1584 10.6568 20.5015 8.99999 20.5015C7.40231 20.5015 6.09633 19.2526 6.00508 17.6778L5.99954 17.4992L1.27485 17.5C1.10351 17.5 0.934005 17.4648 0.776852 17.3965C0.14365 17.1215 -0.146699 16.3852 0.12834 15.752L1.49999 12.5942V8.49612C1.50059 4.34133 4.85208 0.996231 8.99999 0.996231ZM10.4995 17.4992L7.49999 17.5015C7.49999 18.3299 8.17156 19.0015 8.99999 19.0015C9.77969 19.0015 10.4204 18.4066 10.4931 17.646L10.4995 17.4992ZM8.99999 2.49623C5.67983 2.49623 3.00047 5.17048 2.99999 8.49623V12.9059L1.65601 16H16.3525L15 12.9068L15.0001 8.50909L14.9964 8.28389C14.8853 5.05041 12.2416 2.49623 8.99999 2.49623Z"
        fill="white"
      />
    </svg>
  );
};

export default NotificationIcon;
