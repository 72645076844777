import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  message,
  Input,
  InputNumber,
  DatePicker,
  Select
} from "antd";
import "./Qoute.css";
import { withRouter } from "react-router-dom";
import { API } from "../../../../API/API";
import UserContext from "../../../UserContext/UserContext";
const dateFormat = "YYYY.MM.DD";
var moment = require("moment");
class QouteAdd extends Component {
  static contextType = UserContext;
  formRef = React.createRef();
  state = {
    tenderid: this.props.tenderid,
    invitationid: this.props.invitationid,
    qouteid: this.props.qouteid,
    qouteamount: null,
    qoutedate: null,
    batchlist: [],
    batchid: null,
    deliverydate: null,
    deliveryday: null,
    dkey: 1,
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    API.get("/getQouteById?qouteid=" + this.state.qouteid + "&tenderid=" + this.state.tenderid).then((response) => {
      if (response.status === 200 && response.data.retType === 0) {
        if (response.data.retData.length > 0) {
          this.setState((prevState) => ({
            qouteamount: response.data.retData[0].length > 0 ? response.data.retData[0][0].qouteamount : null,
            qoutedate: response.data.retData[0].length > 0 ? response.data.retData[0][0].qoutedate : null,
            deliverydate: response.data.retData[0].length > 0 ? response.data.retData[0][0].deliverydate : null,
            batchid: response.data.retData[0].length > 0 ? response.data.retData[0][0].batchid : null,
            batchlist: response.data.retData[1],
            dkey: prevState.dkey + 1,
          }));
        }
      }
    });
  };
  onChangeAmount = (value) => {
    this.setState({ qouteamount: value });
  };
  onChangeDate = (value) => {
    this.setState({ qoutedate: value });
  };
  onChangeDeliveryDate = (value) => {
    this.setState({ deliverydate: value });
  };
  onChangeDay = (value) => {
    this.setState({ deliveryday: value });
  };

  onSave = () => {
    if (this.state.qouteamount && this.state.qoutedate && this.state.deliverydate){
      let body = {
        tenderid: this.state.tenderid,
        invitationid: this.state.invitationid,
        qouteid: this.state.qouteid,
        qouteamount: this.state.qouteamount,
        qoutedate: this.state.qoutedate,
        deliverydate: this.state.deliverydate,
        vendorid: this.context.vendorid,
        createdby: this.context.userid,
        batchid: this.state.batchid ? this.state.batchid : 0
      };
      API.post("/saveQoute", body).then((response) => {
        if (response.status === 200 && response.data.retType === 0) {
          this.props.showForm(false, 0);
          message.success("Амжилттай хадгаллаа.");
        }
      });
    }
  };
  onChangeBatch = (value) => {
    this.setState({ batchid: value });
  };
  render() {
    return (
      <div id="qoute" key={this.state.dkey}>
        <Form
          className="formDrawer"
          ref={this.formRef}
          onFinish={this.onSubmit}
          layout="vertical"
          initialValues={{}}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                style={{ fontWeight: 700 }}
                label="Тендерийн нийт үнэ"
                name="qouteamount"
                rules={[
                  {
                    required: true,
                    message: "Тендерийн нийт үнэ оруулна уу.",
                  },
                ]}
                initialValue={this.state.qouteamount}
              >
                <InputNumber
                  placeholder="___._"
                  style={{ width: "100%" }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  align="right"
                  defaultValue={this.state.qouteamount}
                  onChange={this.onChangeAmount}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                style={{ fontWeight: 700 }}
                label="Хүчинтэй хугацаа"
                name="qoutedate"
                rules={[
                  {
                    required: true,
                    message: "Огноо сонгоно уу.",
                  },
                ]}
                initialValue={
                  this.state.qoutedate
                    ? moment(this.state.qoutedate, dateFormat)
                    : null
                }
              >
                <DatePicker
                  value={
                    this.state.qoutedate
                      ? moment(this.state.qoutedate, dateFormat)
                      : null
                  }
                  onChange={this.onChangeDate}
                  format={dateFormat}
                  size="middle"
                  style={{ width: "100%" }}
                  placeholder=""
                ></DatePicker>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
            <Form.Item
                style={{ fontWeight: 700 }}
                label="Бараа нийлүүлэх болон ажил гүйцэтгэх эцсийн хугацаа"
                name="deliverydate"
                rules={[
                  {
                    required: true,
                    message: "Огноо сонгоно уу.",
                  },
                ]}
                initialValue={
                  this.state.deliverydate
                    ? moment(this.state.deliverydate, dateFormat)
                    : null
                }
              >
                <DatePicker
                  value={
                    this.state.deliverydate
                      ? moment(this.state.deliverydate, dateFormat)
                      : null
                  }
                  onChange={this.onChangeDeliveryDate}
                  format={dateFormat}
                  size="middle"
                  style={{ width: "100%" }}
                  placeholder=""
                ></DatePicker>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                style={{ fontWeight: 700 }}
                label="Багц сонгох"
                name="batchid"
                initialValue={this.state.batchid}
              >
                <Select
                  style={{ fontWeight: 400, width: "100%", height: 30 }}
                  showSearch
                  allowClear
                  optionFilterProp="name"
                  onChange={this.onChangeBatch}
                  defaultValue={this.state.batchid}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.batchlist &&
                    this.state.batchlist?.map((t, i) => (
                      <Select.Option key={i} value={t.batchid}>
                        {t.batchname}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="buttonForm" label=" ">
            <Button
              type="primary"
              style={{
                width: "100%",
                backgroundColor: "#0358A7",
                borderRadius: 2,
                height: 40,
              }}
              htmlType="submit"
              onClick={this.onSave}
            >
              Үнийн санал оруулах
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default withRouter(QouteAdd);
