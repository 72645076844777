import React, { Component } from "react";
import {
  Row,
  Col,
  Input,
  Checkbox,
  Button,
  message,
  Radio,
  Select,
  DatePicker,
} from "antd";
import CryptoJS from "crypto-js";
import { withRouter } from "react-router-dom";
import { API } from "../../API/API";
import axios from "axios";
import { baseConfig } from "../../Config/Config";
import "./Login.css";

import LeftSide from "./LeftSide";
import BackArrow from "../../svg/svgicon/BackArrow";
import { resolveOnChange } from "antd/lib/input/Input";
const dateFormat = "YYYY.MM.DD";
var moment = require("moment");
class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendortypeid: 0,
      countries: [],
      countryname: "Mongolia",
      vendorname: "",
      registernumber: "",
      isvatpayer: 1,
      activity: "",
      activitylist: [],
      subactivitylist: [],
      vendorstatusid: 1,
      establisheddate: "",
      address: "",
      vendorphone: "",
      vendoremail: "",
      empname: "",
      empphone: "",
      empemail: "",
      username: "",
      password: "",
      passwordRpt: "",
      headcompany: "",
      shareholder: "",
      website: "",
      vendorcategory_ids: [],
      vendorcategoryoptions: [],
      activityid: "",
      subactivityid: ""
  
    };
  }

  componentDidMount = () => {
    axios.get("/api/v0.1/countries", {
      baseURL: "https://countriesnow.space",
    }).then((response) => {
      if (response.status === 200) {
        this.setState({
          countries: response.data.data,
        });
      }
    });
    API.get("/getVendorActivityList").then((response) => {
      if (response.status === 200) {
        this.setState({
          activitylist: response.data.retData,
        });
      }
    });
    // API.get("/getVendorSubActivityList").then((response) => {
    //   if (response.status === 200) {
    //     this.setState({
    //       subactivitylist: response.data.retData,
    //     });
    //   }
    // });
    API.post('/getVendorCategory', { url: baseConfig.makurl }).then((response) => { 
      if (response.status === 200) {
        let tmp = [];
        response.data.retData.map((category, index) => {
          if (category.parent_id === null) {
            let formated_category = {
              label: category.name,
              options: []
            }
            response.data.retData.map((sub_category, sub_index) => {
              if (sub_category.parent_id === category.id) {
                formated_category.options.push({ label: sub_category.name, value: sub_category.id })
              }
            })
            tmp.push(formated_category);
          }
        })
        this.setState({
          vendorcategoryoptions: tmp
        })
      }
    });
   
  };
  componentDidUpdate(prevProps, prevState) {
    // Runs on updates
    // Actions based on prop/state changes
  }

  onRegister = () => {
    if (this.state.password != this.state.passwordRpt) {
      message.warning("Давтан оруулсан нууц үг зөрүүтэй байна!");
    } else {
      if (
        this.state.countryname &&
        this.state.vendorname &&
        this.state.registernumber &&
        this.state.vendorcategory_ids &&
        this.state.username &&
        this.state.password
      ) {
        if (this.state.vendortypeid != 2) {
          if (
            this.state.establisheddate &&
            this.state.address &&
            this.state.vendorphone &&
            this.state.vendoremail &&
            this.state.empname &&
            this.state.empphone &&
            this.state.empemail
          ) {
            this.saveRegister();
          }
          else {
            message.warning("Заавал бөглөх талбарууд дутуу байна.");
          }
        }
        else {
          this.saveRegister();
        }
      } else {
        message.warning("Заавал бөглөх талбарууд дутуу байна.");
      }
    }
  };

  saveRegister = () => {
    let body = {
      vendorid: 0,
      vendortypeid: this.state.vendortypeid,
      countryname: this.state.countryname,
      vendorname: this.state.vendorname,
      registernumber: this.state.registernumber,
      isvatpayer: this.state.isvatpayer,
      establisheddate: this.state.establisheddate,
      activity: this.state.activity,
      vendorstatusid: this.state.vendorstatusid,
      address: this.state.address,
      vendorphone: this.state.vendorphone,
      vendoremail: this.state.vendoremail,
      bankname: "",
      bankaccountname: "",
      bankaccountnumber: "",
      username: this.state.username,
      empname: this.state.empname,
      empphone: this.state.empphone,
      empemail: this.state.empemail,
      password: this.state.password,
      website: this.state.website,
      shareholder: this.state.shareholder,
      headcompany: this.state.headcompany,
      vendorcategory_ids: this.state.vendorcategory_ids + ""
    };
    API.post("/saveVendor", body).then((response) => {
      if (response.status === 200 && response.data.retType == 0) {
        message.success("Амжилттай бүртгэгдлээ.");
        this.props.history.push("/login");
      }
    });
  }

  onChangeEstablishedDate = (value) => {
    this.setState({ establisheddate: moment(value, dateFormat) });
  };

  onChangeCountry = (e) => {
    this.setState({ countryname: e });
  };

  onChangeVendorType = (e) => {
    console.log(e.target.value);
    if (e.target.value === 3) {
      this.setState({ vendortypeid: e.target.value, countryname: "" });
    } else {
      this.setState({ vendortypeid: e.target.value, countryname: "Mongolia" });
    }
  };

  onChangeVendorName = (e) => {
    this.setState({ vendorname: e.target.value });
  };

  onChangeRegno = (e) => {
    this.setState({ registernumber: e.target.value });
  };

  onChangeIsvat = (value) => {
    this.setState({ isvatpayer: value });
  };

  onchangeActivity = (e) => {
    this.setState({ activity: e });
    this.setState({ activityid: e});
    console.log("activityid=", this.state.activityid)

  };
  onchangeCategoryids = (e) => {
    // activity
    this.setState({ vendorcategory_ids: e });
  };
  onchangeStatus = (value) => {
    this.setState({ vendorstatusid: value });
  };

  onChangeUserName = (e) => {
    this.setState({ username: e.target.value });
  };

  onChangePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  onChangePasswordRpt = (e) => {
    this.setState({ passwordRpt: e.target.value });
  };

  onChangeAddress = (e) => {
    this.setState({ address: e.target.value });
  };

  onChangeVendorPhone = (e) => {
    this.setState({ vendorphone: e.target.value });
  };

  onChangeVendorEmail = (e) => {
    this.setState({ vendoremail: e.target.value });
  };

  onChangeEmpName = (e) => {
    this.setState({ empname: e.target.value });
  };

  onChangeEmpPhone = (e) => {
    this.setState({ empphone: e.target.value });
  };

  onChangeEmpEmail = (e) => {
    this.setState({ empemail: e.target.value });
  };

  onChangeWebSite = (e) => {
    this.setState({ website: e.target.value });
  };

  onChangeHeadCompany = (e) => {
    this.setState({ headcompany: e.target.value });
  };

  onChangeShareHolder = (e) => {
    this.setState({ shareholder: e.target.value });
  };

  onCallLogin = () => {
    this.props.history.push("/login");
  };

  render() {
    return (
      <div className="loginContainer">
        <div className="login">
           <LeftSide />
            <div className="loginRighftSideRegister">
              <Row justify="start" align="middle" style={{ marginBottom: 15 }}>
                <Col style={{ marginRight: 5, marginTop: 10 }}>
                  <Button
                    type="link"
                    onClick={this.onCallLogin}
                    icon={<BackArrow />}
                  />
                </Col>
                <Col>
                  <div className="RegisterHeader">Бүртгүүлэх</div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col sm = {16} md={16} lg={12} xl={12}>
                  <div className="RegisterLabel">Төрөл</div>
                  <Radio.Group
                    onChange={this.onChangeVendorType}
                    defaultValue={this.state.vendortypeid}
                  >
                    <Row gutter={(16, 16)}>
                      <Col span={8}>
                        <Radio value={1}>ААН</Radio>
                      </Col>
                      <Col span={10}>
                        <Radio value={2}>Хувь хүн</Radio>
                      </Col>
                      <Col span={6}>
                        <Radio value={3}>Гадаад</Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Col>
                <Col span={12}>
                  <div className="RegisterLabel">
                    Улс <text style={{ color: "red" }}>*</text>
                  </div>
                  <Select
                    style={{ width: "100%", height: 30 }}
                    showSearch
                    allowClear
                    optionFilterProp="name"
                    onChange={this.onChangeCountry}
                    defaultValue={this.state.countryname}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.countries &&
                      this.state.countries?.map((t, i) => (
                        <Select.Option key={i} value={t.country}>
                          {t.country}
                        </Select.Option>
                      ))}
                  </Select>
                </Col>
                <Col span={12}>
                  <div className="RegisterLabel">
                    Нэр <text style={{ color: "red" }}>*</text>
                  </div>
                  <Input
                    size="middle"
                    defaultValue={this.state.vendorname}
                    onChange={this.onChangeVendorName}
                    style={{ borderRadius: 4 }}
                  />
                </Col>
                <Col span={12}>
                  <div className="RegisterLabel">
                    Регистрийн дугаар <text style={{ color: "red" }}>*</text>
                  </div>
                  <Input
                    size="middle"
                    defaultValue={this.state.registernumber}
                    onChange={this.onChangeRegno}
                    style={{ borderRadius: 4 }}
                  />
                </Col>
                <Col span={12}>
                  <div className="RegisterLabel">НӨАТ төлөгч эсэх</div>
                  <Select
                    size="middle"
                    style={{ width: "100%", borderRadius: 4 }}
                    onChange={this.onChangeIsvat}
                    defaultValue={this.state.isvatpayer}
                  >
                    <Select.Option key={1} value={1}>
                      Тийм
                    </Select.Option>
                    <Select.Option key={2} value={0}>
                      Үгүй
                    </Select.Option>
                  </Select>
                </Col>
                {/* <Col span={12}>
                  <div className="RegisterLabel">
                    Үйл ажиллагааны чиглэл{" "}
                    <text style={{ color: "red" }}>*</text>
                  </div>
                  <Select
                    style={{ width: "100%", height: 30 }}
                    showSearch
                    allowClear
                    optionFilterProp="name"
                    onChange={this.onchangeActivity}
                    defaultValue={this.state.activityid}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.activitylist &&
                      this.state.activitylist?.map((t, i) => (
                        <Select.Option key={i} value={t.activityid}>
                          {t.activity}
                        </Select.Option>
                      ))}
                  </Select>
                </Col> */}
                <Col span={12}>
                  <div className="RegisterLabel">Компанийн статус</div>
                  <Select
                    size="middle"
                    style={{ width: "100%", borderRadius: 4 }}
                    onChange={this.onchangeStatus}
                    defaultValue={this.state.vendorstatusid}
                  >
                    <Select.Option key={1} value={1}>
                      Импортлогч
                    </Select.Option>
                    <Select.Option key={2} value={0}>
                      Дистрибьютер
                    </Select.Option>
                    <Select.Option key={3} value={2}>
                      Үйлдвэрлэгч
                    </Select.Option>
                    <Select.Option key={4} value={3}>
                      Төлөөлөгч
                    </Select.Option>
                  </Select>
                </Col>
                <Col span={24}>
                <div className="RegisterLabel">
                Үйл ажиллагааны чиглэл{" "}
                    <text style={{ color: "red" }}>*</text>
                  </div>
                  
                  <Select
                    style={{ width: "100%", height: 30 }}
                    showSearch
                    allowClear
                    optionFilterProp="name"
                    onChange={this.onchangeCategoryids}
                    defaultValue={this.state.vendorcategory_ids}
                    mode="multiple"
                    options={this.state.vendorcategoryoptions}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  ></Select>
                </Col>
                <Col span={12}>
                  <div className="RegisterLabel">
                    Компани байгуулагдсан огноо{" "}
                    {this.state.vendortypeid != 2 ? <text style={{ color: "red" }}>*</text> : null}
                  </div>
                  <DatePicker
                    defaultValue={
                      this.state.establisheddate
                        ? moment(this.state.establisheddate, dateFormat)
                        : null
                    }
                    onChange={this.onChangeEstablishedDate}
                    format={dateFormat}
                    style={{ width: "100%", height: 30 }}
                    placeholder=""
                  ></DatePicker>
                </Col>
                <Col span={24}>
                  <div className="RegisterLabel">
                    Компанийн хаяг
                    {this.state.vendortypeid != 2 ? <text style={{ color: "red" }}>*</text> : null}
                  </div>
                  <Input
                    size="middle"
                    value={this.state.address}
                    onChange={this.onChangeAddress}
                    style={{ borderRadius: 4 }}
                  />
                </Col>
                <Col span={12}>
                  <div className="RegisterLabel">
                    Байгууллагын утас
                    {this.state.vendortypeid != 2 ? <text style={{ color: "red" }}>*</text> : null}
                  </div>
                  <Input
                    size="middle"
                    value={this.state.vendorphone}
                    onChange={this.onChangeVendorPhone}
                    style={{ borderRadius: 4 }}
                  />
                </Col>
                <Col span={12}>
                  <div className="RegisterLabel">
                    Байгууллагын и-мэйл хаяг{" "}
                    {this.state.vendortypeid != 2 ? <text style={{ color: "red" }}>*</text> : null}
                  </div>
                  <Input
                    size="middle"
                    value={this.state.vendoremail}
                    onChange={this.onChangeVendorEmail}
                    style={{ borderRadius: 4 }}
                  />
                </Col>
                <Col span={12}>
                  <div className="RegisterLabel">
                    Толгой компани
                  </div>
                  <Input
                    size="middle"
                    value={this.state.headcompany}
                    onChange={this.onChangeHeadCompany}
                    style={{ borderRadius: 4 }}
                  />
                </Col>
                <Col span={12}>
                  <div className="RegisterLabel">
                    Хувьцаа эзэмшигч
                  </div>
                  <Input
                    size="middle"
                    value={this.state.shareholder}
                    onChange={this.onChangeShareHolder}
                    style={{ borderRadius: 4 }}
                  />
                </Col>
                <Col span={12}>
                  <div className="RegisterLabel">
                    Вэб сайт
                  </div>
                  <Input
                    size="middle"
                    value={this.state.website}
                    onChange={this.onChangeWebSite}
                    style={{ borderRadius: 4 }}
                  />
                </Col>
              </Row>
              <div
                className="RegisterSubHeader"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                Холбоо барих ажилтны мэдээлэл
              </div>
              <Row gutter={(16, 16)}>
                <Col span={12}>
                  <div className="RegisterLabel">
                    Нэр
                    {this.state.vendortypeid != 2 ? <text style={{ color: "red" }}>*</text> : null}
                  </div>
                  <Input
                    size="middle"
                    value={this.state.empname}
                    onChange={this.onChangeEmpName}
                    style={{ borderRadius: 4 }}
                  />
                </Col>
                <Col span={12}>
                  <div className="RegisterLabel">
                    Утас
                    {this.state.vendortypeid != 2 ? <text style={{ color: "red" }}>*</text> : null}
                  </div>
                  <Input
                    size="middle"
                    value={this.state.empphone}
                    onChange={this.onChangeEmpPhone}
                    style={{ borderRadius: 4 }}
                  />
                </Col>
                <Col span={12}>
                  <div className="RegisterLabel">
                    И-мэйл хаяг
                    {this.state.vendortypeid != 2 ? <text style={{ color: "red" }}>*</text> : null}
                  </div>
                  <Input
                    size="middle"
                    value={this.state.empemail}
                    onChange={this.onChangeEmpEmail}
                    style={{ borderRadius: 4 }}
                  />
                </Col>
              </Row>
              <div
                className="RegisterSubHeader"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                Нууц үг
              </div>
              <Row gutter={(16, 16)}>
                <Col span={12}>
                  <div className="RegisterLabel">
                    Системд нэвтрэх нэр <text style={{ color: "red" }}>*</text>
                  </div>
                  <Input
                    size="middle"
                    defaultValue={this.state.username}
                    onChange={this.onChangeUserName}
                    style={{ borderRadius: 4 }}
                  />
                </Col>
              </Row>
              <Row gutter={(16, 16)}>
                <Col span={12}>
                  <div className="RegisterLabel">
                    Нууц үг оруулах <text style={{ color: "red" }}>*</text>
                  </div>
                  <Input.Password
                    size="large"
                    defaultValue={this.state.password}
                    onChange={this.onChangePassword}
                    style={{ borderRadius: 8 }}
                    placeholder="Нууц үг"
                  />
                </Col>
                <Col span={12}>
                  <div className="RegisterLabel">
                    Нууц үг давтан оруулах{" "}
                    <text style={{ color: "red" }}>*</text>
                  </div>
                  <Input.Password
                    size="large"
                    onChange={this.onChangePasswordRpt}
                    defaultValue={this.state.passwordRpt}
                    style={{ borderRadius: 8 }}
                    placeholder="Нууц үг"
                  />
                </Col>
                <Col span={12} style={{ marginTop: 15 }}>
                  <Button
                    type="primary"
                    className="loginButton"
                    style={{ borderRadius: 8 }}
                    onClick={this.onRegister}
                  >
                    Бүртгүүлэх
                  </Button>
                </Col>
              </Row>

              {/* <Form.Item>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      required: true,
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ],})(<Input placeholder="Email" />)}
              </Form.Item>; */}
            </div>
        </div>
      </div>  
    );
  }
}
export default withRouter(Register);
