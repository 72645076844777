import React, { Component } from "react";
import { Row, Col,Form, Input, Checkbox, Button, message } from "antd";
import CryptoJS from "crypto-js";
import { withRouter } from "react-router-dom";
import { API } from "../../API/API";
import { baseConfig } from "../../Config/Config";
import "./Login.css";
import LeftSide from "./LeftSide";
class Login extends Component {
  state = {
    username: "",
    password: "",
    saveusername: false,
  };

  componentDidMount = () => {
    let vusername = localStorage.getItem("username");
    if (vusername) {
      this.setState({
        username: vusername,
        saveusername: true,
      });
    }
  };

  onChangeSaveUserName = (value) => {
    this.setState({
      saveusername: value.target.checked,
    });
  };

  onLogin = () => {
    if (this.state.username && this.state.password) {
      let username = this.state.username;
      let password = this.state.password; //this.encrypt(this.state.password);

      const param = {
        loginUrl: "",
        type: "Vendor",
        username: username,
        password: password,
      };

      API.post("/api/tenderauth/login", param, {
        baseURL: baseConfig.loginurl,
      }).then((response) => {
        if (
          response.status === 200 &&
          response.data.retData.token &&
          response.data.retData.vendorid > -1
        ) {
          localStorage.setItem("access_token", response.data.retData.token);
          localStorage.setItem("expires_at", response.data.retData.tokenexpiry);
          localStorage.setItem("vendorid", response.data.retData.vendorid)
          if (this.state.saveusername) {
            localStorage.setItem("username", username);
          } else {
            localStorage.removeItem("username");
          }
          this.props.history.push("/home");
          this.props.successLogin(
            response.data.retData.username,
            baseConfig.companyid,
            response.data.retData.vendorid
          );
        }
      });
    } else {
      message.warning("Нэвтрэх нэр болон нууц үг оруулна уу!");
    }
  };

  encrypt = (number) => {
    var key = CryptoJS.enc.Utf8.parse("7061737323313233");
    var iv = CryptoJS.enc.Utf8.parse("7061737323313233");
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(number), key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  };

  onChangeUserName = (username) => {
    this.setState({
      username: username,
    });
  };

  onChangePassword = (password) => {
    this.setState({
      password: password,
    });
  };

  onCallRegister = () => {
    this.props.history.push("/register");
  };

  onCallReset = () => {
    this.props.history.push("/resetpwd");
  };

  render() {
    return (
      <div className="loginContainer">
        <div className="login">
             <LeftSide />
              <div className="loginRighftSide">
                <div className="loginFormDiv">
                  <div className="Largelabel">Нэвтрэх</div>
                  <Form
                      name="basic"
                      labelCol={{span: 8,}}
                      wrapperCol={{span: 16,}}
                      initialValues={{remember: true,}}
                      
                      // onFinish={onFinish}
                      // onFinishFailed={onFinishFailed}
                      autoComplete="off"  layout="vertical">
                    <Form.Item
                      label="Нэвтрэх нэр"
                      name="username"
                      rules={[{
                          required: true,
                          message: 'Нэвтрэх нэрээ оруулна уу!',},
                      ]}
                      className="LoginLabel"
                    >
                      <Input  value={this.state.username} onChange={(e) => this.onChangeUserName(e.target.value)}/>
                    </Form.Item>

                    <Form.Item
                      label="Нууц үг"
                      name="password"
                      rules={[{
                          required: true,
                          message: 'Нууц үгээ оруулна уу!',},
                      ]}
                      className="LoginLabel"
                    >
                       <Input.Password onPressEnter={this.onLogin} onChange={(e) => this.onChangePassword(e.target.value)}/>
                    </Form.Item>

                    <Form.Item
                      name="remember"
                      valuePropName="checked"
                      wrapperCol={{ offset: 4, span: 16,}}>
                       <Checkbox checked={this.state.saveusername} onChange={this.onChangeSaveUserName}>Remember me</Checkbox>
                       &nbsp;&nbsp;
                       <Button onClick={this.onCallReset} type="text" className="loginText">
                            Нууц үг сэргээх
                          </Button>
                    </Form.Item>  
                    <Form.Item wrapperCol={{span: 16,}}>
                      <Button type="primary" htmlType="submit" className="loginButton" style={{ borderRadius: 8 }}
                                        onClick={this.onLogin}>Нэвтрэх</Button>
                    </Form.Item>
                    <Form.Item  wrapperCol={{span: 16,}}>
                        <Button type="text"
                          className="loginText"
                          style={{ fontWeight: 700 }}
                          onClick={this.onCallRegister}> Бүртгүүлэх </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Login);
