import React, { Component } from "react";
import { Row, Col, Input, Checkbox, Button, message, Form } from "antd";
import CryptoJS from "crypto-js";
import { withRouter } from "react-router-dom";
import { API } from "../../API/API";
import { baseConfig } from "../../Config/Config";
import "./ResetPwd.css";
import LeftSide from "./LeftSide";
class ResetPwd extends Component {
  state = {
    email: "",
    verificationcode: "",
    username: "",
    password: "",
    repeatpwd: "",
    saveusername: false,
  };

  componentDidMount = () => {
    let vusername = localStorage.getItem("username");
    if (vusername) {
      this.setState({
        username: vusername,
        saveusername: true,
      });
    }
  };

  onChangeSaveUserName = (value) => {
    this.setState({
      saveusername: value.target.checked,
    });
  };

  sendResetPwd = () => {
    if (this.state.email) {
        const param = {
            url: baseConfig.makurl,
            email: this.state.email
        };

        API.post("/api/tenderauth/resetPassword", param, {
            baseURL: baseConfig.loginurl,
        }).then((response) => {
            if (
                response.status === 200 && response.data.retType === 0
            ) {
                message.success("6 оронтой баталгаажуулах код бүртгэлтэй и-мэйл хаяг руу илгээгдлээ.");
            }
        });
    }
    else{
        message.warning("И-мэйл хаяг оруулна уу.");
    }
  };

  onReset = () => {
    if (this.state.username && this.state.password && this.state.verificationcode && this.state.repeatpwd) {
      if (this.state.password === this.state.repeatpwd){
        let username = this.state.username;
        let password = this.state.password;
        let verificationcode = this.state.verificationcode;
        const param = {
          verificationcode: verificationcode,
          username: username,
          password: password,
        };
  
        API.post("/api/tenderauth/saveResetPwd", param, {
          baseURL: baseConfig.loginurl,
        }).then((response) => {
          if (
            response.status === 200 && response.data.retType === 0
          ) {
            message.success("Нэвтрэх нэр нууц үг амжилттай хадгалагдлаа.");
          }
        });
      }
      else{
        message.warning("Давтан оруулсан нууц үг зөрүүтэй байна!");
      }
    }
  };

  onChangeVerification = (code) => {
    this.setState({
      verificationcode: code,
    });
  };

  onChangeUserName = (username) => {
    this.setState({
      username: username,
    });
  };

  onChangePassword = (password) => {
    this.setState({
      password: password,
    });
  };

  onChangeRepeatPwd = (repeatpwd) => {
    this.setState({
      repeatpwd: repeatpwd,
    });
  };

  onChangeEmail = (email) => {
    this.setState({
      email: email,
    });
  };

  onCallLogin = () => {
    this.props.history.push("/login");
  };

  render() {
    return (
      <div className="loginContainer">
      <div className="login">
           <LeftSide />
            <div className="loginRighftSide">
              <div className="loginFormDiv" style={{ height: 800 }}>
                <div className="Largelabel">Нууц үг сэргээх</div>
                <Form
                    name="basic"
                    labelCol={{span: 8,}}
                    wrapperCol={{span: 24,}}
                    initialValues={{remember: true,}}
                    autoComplete="off"  layout="vertical">
                  <Form.Item
                    label="И-мэйл"
                    name="email"
                    className="LoginLabel"> 
                    <Input size="large" onChange={(e) => this.onChangeEmail(e.target.value)} style={{ borderRadius: 8 }}/>
                  </Form.Item>

                  <Form.Item wrapperCol={{span: 24,}}>
                    <Button type="primary" htmlType="submit" className="loginButton" style={{ borderRadius: 8 }} onClick={this.sendResetPwd}>Илгээх</Button>
                  </Form.Item>

                  <Form.Item
                    label="Баталгаажуулах код"
                    name="verificationcode"
                    className="LoginLabel" style={{ paddingTop: "20px" }}
                  > <Input size="large" value={this.state.verificationcode} onChange={(e) => this.onChangeVerification(e.target.value)} style={{ borderRadius: 8 }}/>
                  </Form.Item>

                  <Form.Item
                    label="Нэвтрэх нэр"
                    name="loginname"
                    className="LoginLabel"
                  > <Input size="large" value={this.state.username} onChange={(e) => this.onChangeUserName(e.target.value)} style={{ borderRadius: 8 }}/>
                  </Form.Item>
                  <Form.Item
                    label="Нууц үг"
                    name="loginpass"
                    className="LoginLabel"
                  > <Input.Password size="large" onPressEnter={this.onLogin} onChange={(e) => this.onChangePassword(e.target.value)} style={{ borderRadius: 8 }}/>
                  </Form.Item>
                  <Form.Item
                    label="Нууц үг давтах"
                    name="loginpassrepeat"
                    className="LoginLabel"
                  > <Input.Password onChange={(e) => this.onChangeRepeatPwd(e.target.value)} style={{ borderRadius: 8 }} />
                  </Form.Item>
                  <Form.Item wrapperCol={{span: 24,}} style={{ marginTop: 20, border:2 }}>
                    <Button type="text" className="loginButton" style={{ borderRadius: 8 }}
                             onClick={this.onReset}>Хадгалах</Button>
                  </Form.Item>
                  <Form.Item wrapperCol={{span: 24}}>
                      <Button type="text"
                        className="loginText"
                        style={{ fontWeight: 700}}
                        onClick={this.onCallLogin}> НЭВТРЭХ</Button>
                  </Form.Item>
                  
                </Form>
              </div>
            </div>
      </div>
    </div>

    );
  }
}
export default withRouter(ResetPwd);
