import React, { Component } from "react";
import { Row, Col, Tabs, Table, Button, Popover, message, Input } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import FileDownload from "js-file-download";
import "./TenderPossibleDtl.css";
import PopConfirm from "../../../Components/MyComponent/PopConfirm";
import SendIcon from "../../../svg/svgicon/SendIcon";
import { API } from "../../../API/API";
import UserContext from "../../UserContext/UserContext";
const { TabPane } = Tabs;
const { TextArea } = Input;
class TenderPossibleDtl extends Component {
  static contextType = UserContext;
  state = {
    tendername: "",
    tenderid: this.props.match.params.tenderid,
    invitationid: this.props.match.params.invid,
    balanceday: "",
    tendercode: "",
    invitationcode: "",
    startdate: "",
    enddate: "",
    departmentname: "",
    tendertypename: "",
    criterialist: [],
    requirelist: [],
    email: "",
    address: "",
    phone: "",
    electron: "",
    post: "",
    tenderdoclist: [],
    additionalDocs: [],
    workDocs: [],
    tenderBatch: [],
    commentlist: [],
    comment: "",
    defaultActiveKey: 1,
    defaultActiveKey2: 1,
    delaynote: "",
    dkey: 1,
  };
  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    API.get("/getInvitationDtl?invitationid=" + this.state.invitationid).then(
      (response) => {
        if (response.status === 200) {
          if (response.data.retData.length > 0) {
            this.setState((prevState) => ({
              tendername: response.data.retData[0][0].tendername,
              departmentname: response.data.retData[0][0].departmentname,
              tendertypename: response.data.retData[0][0].tendertypename,
              tendercode: response.data.retData[0][0].tendercode,
              invitationcode: response.data.retData[0][0].invitationcode,
              description: response.data.retData[0][0].description,
              startdate: response.data.retData[0][0].startdate,
              enddate: response.data.retData[0][0].enddate,
              balanceday: response.data.retData[0][0].balanceday,
              delaynote: response.data.retData[0][0].delaynote,
              criterialist: response.data.retData[1],
              requirelist: response.data.retData[2],
              address: response.data.retData[3][0].address,
              email: response.data.retData[3][0].email,
              phone: response.data.retData[3][0].phone,
              electron:
                response.data.retData[3][0].electron === 1 ? "Цахим" : "",
              post:
                response.data.retData[3][0].post === 1 ? "Хаягаар ирүүлэх" : "",
              tenderdoclist: response.data.retData[4],
              tenderBatch: response.data.retData[5],
              commentlist: response.data.retData[6],
              dkey: prevState.dkey + 1,
            }));
          }
        }
      }
    );
  };
  onDownLoadFile = (sourceid, sourcetype, filename) => {
    console.log(sourceid, sourcetype, filename);
    API.get("/download?sourceid=" + sourceid + "&sourcetype=" + sourcetype, {
      responseType: "blob",
    }).then((response) => {
      if (response.status === 200) {
        console.log(response);
        FileDownload(response.data, filename);
      }
    });
  };
  onChangeComment = (value) => {
    this.setState({ comment: value });
  };
  onSendComment = () => {
    let body = {
      commentid: 0,
      commenttitle: this.context.userid,
      comment: this.state.comment,
      vendorid: this.context.vendorid,
      invitationid: this.state.invitationid,
    };
    API.post("/saveComment", body).then((response) => {
      if (response.status === 200 && response.data.retType == 0) {
        message.success("Амжилттай илгээгдлээ.");
        this.setState((prevState) => ({
          commentlist: [...this.state.commentlist, body],
          comment: "",
          defaultActiveKey: 2,
          defaultActiveKey2: 3,
          dkey: prevState.dkey + 1,
        }));
      }
    });
  };
  popConfirm = () => {
    return (
      <PopConfirm
        question="Та тендерт оролцохдоо итгэлтэй байнуу?"
        onClickYes={this.onCallTenderJoin}
        onClickNo={() => this.onChangePopConfirmVisible(false)}
      />
    );
  };
  onChangePopConfirmVisible = (visible) => {
    this.setState({ popConfirmVisible: visible });
  };
  onCallTenderJoin = () => {
    this.setState({ popConfirmVisible: false });
    API.get(
      "/checkInvitationForVendor?vendorid=" +
        this.context.vendorid +
        "&invitationid=" +
        this.state.invitationid
    ).then((response) => {
      if (response.status === 200 && response.data.retType === 0) {
        if (response.data.retData[0].count > 0) {
          message.warning(
            "Уучлаарай та энэ тендерт материалаа илгээсэн байна. Оролцож буй тендерийн жагсаалтаас илгээсэн материалаа харах болон засах боломжтой."
          );
        } else {
          this.props.history.push(
            "/tenderjoin/" +
              true +
              "/" +
              this.state.invitationid +
              "/" +
              this.state.invitationcode +
              "/" +
              this.state.tenderid +
              "/" +
              this.state.tendercode +
              "/" +
              this.state.tendername +
              "/0/" +
              this.state.enddate +
              "/" +
              this.state.tendertypename
          );
        }
      }
    });
  };
  render() {
    const columnDocs = [
      {
        title: "Баримт бичгийн төрөл",
        dataIndex: "doctypename",
        key: "doctypename",
        width: "25%",
        align: "left",
      },
      {
        title: "Баримт бичиг",
        dataIndex: "documentname",
        key: "documentname",
        width: "25%",
        align: "left",
        render: (text, record) => {
          return (
            <Button
              style={{ fontWeight: "500", color: "#0358A7" }}
              type="link"
              onClick={() =>
                this.onDownLoadFile(
                  record.sourceid,
                  record.sourcetype,
                  record.filename
                )
              }
            >
              {record.documentname}
            </Button>
          );
        },
      },
      {
        title: "Багцын код",
        dataIndex: "batchcode",
        key: "batchcode",
        width: "15%",
        align: "left",
      },
      {
        title: "Багцын нэр",
        dataIndex: "batchname",
        key: "batchname",
        width: "15%",
        align: "left",
      },
      {
        title: "Үүсгэсэн огноо",
        dataIndex: "created",
        key: "created",
        width: "25%",
        align: "left",
      },
    ];
    const columnBatch = [
      {
        title: "Багцын код",
        dataIndex: "batchcode",
        key: "batchcode",
        width: "45%",
        align: "left",
      },
      {
        title: "Багцын нэр",
        dataIndex: "batchname",
        key: "batchname",
        width: "45%",
        align: "left",
      },
    ];
    const columnCriteria = [
      {
        title: "Шалгуурын төрөл",
        dataIndex: "criteriatypename",
        key: "criteriatypename",
        width: "45%",
        align: "left",
      },
      {
        title: "Нэмэлт тайлбар",
        dataIndex: "criterianame",
        key: "criterianame",
        width: "45%",
        align: "left",
      },
    ];
    return (
      <div id="tenderPossible" key={this.state.dkey}>
        <div style={{ padding: 20 }}>
          <Row gutter={(16, 16)}>
            <Col span={12}>
              <Row justify="start">
                <div className="HeaderLabel" style={{ marginBottom: 15 }}>
                  {this.state.tendername}
                </div>
              </Row>
            </Col>
            <Col span={12}>
              <Row justify="end">
                <Popover
                  placement="bottomRight"
                  title="Санамж"
                  content={this.popConfirm}
                  trigger="click"
                  visible={this.state.popConfirmVisible}
                >
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#0358A7",
                      borderRadius: 4,
                      marginTop: 20,
                    }}
                    htmlType="submit"
                    onClick={() => this.onChangePopConfirmVisible(true)}
                  >
                    Тендерт оролцох
                  </Button>
                </Popover>
              </Row>
            </Col>
          </Row>
          <Tabs defaultActiveKey={this.state.defaultActiveKey}>
            <TabPane tab="Тендерийн мэдээлэл" key={1}>
              <div style={{ padding: 15 }}>
                <Row gutter={(16, 16)}>
                  <Col className="headerTable-leftSide" span={12}>
                    <div className="headerTable-leftSide-Text">Захиалагч:</div>
                  </Col>
                  <Col className="headerTable-rightSide" span={12}>
                    <div className="headerTable-rightSide-Text">
                      Монголын алт ХХК - Хангамж техник үйлчилгэээний газар
                    </div>
                  </Col>
                  <Col className="headerTable-leftSide" span={12}>
                    <div className="headerTable-leftSide-Text">
                      Тендер шалгаруулалтын төрөл:
                    </div>
                  </Col>
                  <Col className="headerTable-rightSide" span={12}>
                    <div className="headerTable-rightSide-Text">
                      {this.state.tendertypename}
                    </div>
                  </Col>
                  <Col className="headerTable-leftSide" span={12}>
                    <div className="headerTable-leftSide-Text">
                      Тендер шалгаруулалтын дугаар:
                    </div>
                  </Col>
                  <Col className="headerTable-rightSide" span={12}>
                    <div className="headerTable-rightSide-Text">
                      {this.state.tendercode}
                    </div>
                  </Col>
                  <Col className="headerTable-leftSide" span={12}>
                    <div className="headerTable-leftSide-Text">
                      Урилгын дугаар:
                    </div>
                  </Col>
                  <Col className="headerTable-rightSide" span={12}>
                    <div className="headerTable-rightSide-Text">
                      {this.state.invitationcode}
                    </div>
                  </Col>
                  <Col className="headerTable-leftSide" span={12}>
                    <div className="headerTable-leftSide-Text">
                      Тендерийн төлөв:
                    </div>
                  </Col>
                  <Col className="headerTable-rightSide" span={12}>
                    <div className="headerTable-rightSide-Text">
                      Тендер хүлээн авч байгаа
                    </div>
                  </Col>
                  <Col className="headerTable-leftSide" span={12}>
                    <div className="headerTable-leftSide-Text">
                      Багцтай эсэх:
                    </div>
                  </Col>
                  <Col className="headerTable-rightSide" span={12}>
                    <div className="headerTable-rightSide-Text">
                      {this.state.tenderBatch.length > 0 ? "Тийм" : "Үгүй"}
                    </div>
                  </Col>
                </Row>
                {this.state.delaynote ? (
                  <div>
                    <div
                      className="content-Text-Bold"
                      style={{ marginTop: 15 }}
                    >
                      Хойшлуулсан:
                    </div>
                    <div
                      className="content-Text"
                      style={{ marginLeft: 10, color: "red" }}
                    >
                      /{this.state.enddate + " хүртэл "}/ {this.state.delaynote}
                    </div>
                  </div>
                ) : null}
                <div style={{ marginBottom: 15, marginTop: 15, fontSize: 18 }}>
                  Тендерийн багц
                </div>
                <div>
                  <Table
                    dataSource={this.state.tenderBatch}
                    columns={columnBatch}
                    pagination={false}
                    rowKey="docid"
                  />
                </div>
              </div>
            </TabPane>
            <TabPane tab="Тендерийн урилга" key={2}>
              <div className="content-Text" style={{ marginTop: 5 }}>
                {this.state.description}
              </div>
              <div className="content-Text-Bold" style={{ marginTop: 15 }}>
                КОМПАНИД ТАВИГДАХ ШААРДЛАГА
              </div>
              {this.state.criterialist.map((el, index) => (
                <div
                  key={index}
                  className="content-Text"
                  style={{ marginLeft: 10 }}
                >
                  {index + 1}. {el.criteriatypename}
                </div>
              ))}
              <div className="content-Text-Bold" style={{ marginTop: 15 }}>
                БҮРДҮҮЛЭХ МАТЕРИАЛЫН ЖАГСААЛТ
              </div>
              {this.state.requirelist.map((el, index) => (
                <div
                  key={index}
                  className="content-Text"
                  style={{ marginLeft: 10 }}
                >
                  {index + 1}. {el.requirevalue}
                </div>
              ))}

              <div style={{ marginTop: 20 }}>
                <text style={{ fontWeight: 700 }}>
                  Хамтран ажиллах саналын материалыг
                </text>{" "}
                {this.state.enddate} хүртэл{" "}
                {this.state.electron !== "" && this.state.post !== ""
                  ? this.state.electron +
                    " болон " +
                    this.state.post +
                    " хэлбэрээр "
                  : this.state.electron !== "" || this.state.post !== ""
                  ? this.state.electron + " " + this.state.post + " хэлбэрээр "
                  : null}{" "}
                хүлээн авна.
              </div>

              <div className="content-Text-Bold" style={{ marginTop: 15 }}>
                Хаяг:{" "}
                <text style={{ fontWeight: 400 }}>{this.state.address}</text>
              </div>
              <div className="content-Text-Bold" style={{ marginTop: 15 }}>
                И-мэйл:{" "}
                <text style={{ fontWeight: 400 }}>{this.state.email}</text>
              </div>
              <div className="content-Text-Bold" style={{ marginTop: 15 }}>
                Вэб сайт: <text style={{ fontWeight: 400 }}>www.mak.mn</text>
              </div>
              <div className="content-Text-Bold" style={{ marginTop: 15 }}>
                Утас:{" "}
                <text style={{ fontWeight: 400 }}>{this.state.phone}</text>
              </div>
              <div className="content-Text-Bold" style={{ marginTop: 15 }}>
                Холбогдох бичиг баримтууд
              </div>
              <div>
                <Tabs defaultActiveKey={this.state.defaultActiveKey2}>
                  <TabPane tab="Нэмэлт баримт бичиг" key={1}>
                    <div id="tender-list" style={{ marginRight: 15 }}>
                      <Table
                        dataSource={this.state.tenderdoclist}
                        columns={columnDocs}
                        pagination={false}
                        rowKey="docid"
                      />
                    </div>
                  </TabPane>
                  <TabPane tab="Тодруулга" key="2">
                    <div id="tender-list" style={{ marginRight: 15 }}>
                      <Table
                        dataSource={this.state.criterialist}
                        columns={columnCriteria}
                        pagination={false}
                        rowKey="docid"
                      />
                    </div>
                  </TabPane>
                  <TabPane tab="Захиа" key={3}>
                    <div
                      style={{
                        padding: 10,
                        backgroundColor: "#fff",
                      }}
                    >
                      <div style={{ overflow: "scroll" }}>
                        {this.state.commentlist.length > 0 ? (
                          <div
                            className="chatbotContent"
                            style={{ height: 280 }}
                          >
                            {this.state.commentlist.map((el) => {
                              return (
                                <div>
                                  <Row justify="start">
                                    <Col>
                                      <div className="Label">
                                        {el.commenttitle}
                                      </div>
                                    </Col>
                                    <Col>
                                      <div
                                        style={{
                                          marginLeft: 10,
                                          color: "#636363",
                                        }}
                                      >
                                        {el.commentdate}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row justify="start">
                                    <Col>
                                      <div className="chatbox">
                                        {el.comment}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                      <div style={{ marginTop: 10 }}>
                        <TextArea
                          placeholder="Захиа бичих"
                          onChange={(e) => this.onChangeComment(e.target.value)}
                          defaultValue={this.state.comment}
                          rows={3}
                        />
                      </div>
                      <Row justify="end">
                        <Col>
                          <Button
                            type="primary"
                            style={{
                              backgroundColor: "#0358A7",
                              borderRadius: 2,
                              height: 32,
                              marginTop: 15,
                            }}
                            icon={<SendIcon />}
                            htmlType="submit"
                            onClick={this.onSendComment}
                          >
                            Илгээх
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default withRouter(TenderPossibleDtl);
